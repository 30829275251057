import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Cookies from "js-cookie";
import { Dispatch } from "redux";
import axios from "axios";
import { jwtDecode } from "jwt-decode"; // Import jwt-decode
import { userAuthentication } from "../redux/UserInfo/isLoggedActions";

// Import your pages
import AddUser from "../pages/AddUser";
import AdminUser from "../pages/AdminUser";
import UserProf from "../pages/UserProf";
import Groups from "../pages/Groups";
import GroupDetails from "../pages/GroupDetails";
import Admins from "../pages/Admins";
import Workspace from "../pages/Workspace";
import SubWork from "../pages/SubWork";
import SubWorkDetails from "../pages/SubWorkDetails";
import AccessPage from "../pages/AccessPage";
import AccessPageforContents from "../pages/AccessPageforContents";
import AccessRestricted from "../pages/AccessRestricted";
import NewDataConnection from "../Components/DataConnection/NewDataConnection";
import {
  localEndPoint,
  serverEndPoint,
} from "./ServerCall/EnvironmentVariables";
import NewDataSet from "./DataSet/NewDataSet";
import EditDataSet from "./DataSet/EditDataSet";
import DataViewer from "./DataViewer/DataViewer";
import EditFlatFileData from "./DataConnection/EditFlatFileData";
import FlatFileUpload from "./DataConnection/FlatFileUpload";
import BackButtonHandler from "./BackButtonHandler";
import { DeleteAllCookies } from "../Components/CommonFunctions/CommonFunctions";

const Home = (props: any) => {

  useEffect(() => {
    const authenticateUser = async () => {
      let decodedToken: any = {};
      try {
        let authToken = Cookies.get("authToken"); 

        const defaultToken =

        "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJzaWRkaGFudC5vdGFAc2lsemlsYS5jb20iLCJhdWRpZW5jZSI6IndlYiIsImFjY2VzcyI6ImFkbWluIiwiaWF0IjoxNzM3NDU5NzEyLCJ0ZW5hbnQiOiJzaWx6aWxhIiwiZXhwIjoxNzM3NTE3MzEyfQ.p9lmGwbngtMqH1P_6ZU_cdlv_uVRGp60QvnmCbyPEJWX-W71O9F4-boTK6xsUO6ytwAhInQYN50_-uDewthyqg";

        if (!authToken) {
          console.log("No Token Found:", authToken);
          localStorage.setItem("accessToken", defaultToken);
          authToken = defaultToken;
        }

        // Create an Axios instance with the auth token
        const axiosInstance = axios.create({
          headers: {
            Authorization: `Bearer ${authToken}`,
            // "X-TENANT-ID": "silzila",
            "Content-type": "application/json",
          },
        });

        // Verify the token with the server
        const response = await axiosInstance.get(`${serverEndPoint}checktoken`);
        console.log("response", response);

        if (response && response.status === 200) {
          localStorage.setItem("accessToken", authToken);
          console.log("Valid accessToken:", authToken);

          // Decode the token to extract user role
          decodedToken = jwtDecode(authToken);

          const userRole = decodedToken.access; 
          const payload = {
            isUserLogged: true,
            accessToken: authToken,
            tokenType: "",
            access: userRole, // Store the role in Redux
            /**
             * below data will be set by Header.tsx
             */
            email: "",
            firstName: "",
            lastName: "",
            avtar: "",
          };

          props.userAuthentication(payload);
        } else {
          console.log("Invalid Token:", authToken);
          // Redirect to signin if token is invalid
          // window.location.href = `${localEndPoint}auth/business/signin`;
        }
      } catch (err) {
        console.error("Authentication Error:", err);

        setTimeout(() => {
          if (window.location.protocol === "https:") {
            if (decodedToken.access === "community") {
              window.location.href = `${localEndPoint}auth/community/signin`;
            } else {
              window.location.href = `${localEndPoint}auth/business/signin`;
            }
          } else {
            const payload = {
              isUserLogged: false,
              accessToken: "",
              tokenType: "",
              access: decodedToken.access, // Store the role in Redux
            };

            props.userAuthentication(payload);
          }
        }, 2000);

        DeleteAllCookies();
        localStorage.clear();
        Cookies.remove("authToken");
      }
    };

    authenticateUser();
  }, [props]);

  useEffect(() => {
    const handleBeforeUnLoad = (event: any) => {
      // Display a confirmation dialog (modern browsers may suppress this)
      event.preventDefault();

      // Setting a returnValue is required for older browsers

      //event.returnValue = "Are you sure you want to leave?";

      return (event.returnValue = "");
      // The event does not always show a confirmation dialog in modern browsers,
      // but this can help detect when the event occurs.
    };

    window.addEventListener("beforeunload", handleBeforeUnLoad);

    return () => window.removeEventListener("beforeunload", handleBeforeUnLoad);
  }, []);

  // Destructure props for easier access
  const { isUserLogged, access } = props;

  // Define role-based route access
  const renderRoutes = () => {
    if (isUserLogged === false) {
      return <Route path="*" element={<Navigate to="/login" replace />} />; // Redirect to login if not logged in
    }

    // Common routes accessible by all roles
    const commonRoutes = [
      { path: "/", element: <Workspace /> },
      { path: "/update-profile", element: <UserProf /> },
      { path: "/workspace/:parentId", element: <SubWork /> },
      { path: "/workspace/access/:parentId", element: <AccessPage /> },
      { path: "/content/access/:parentId", element: <AccessPageforContents /> },
      { path: "/SubWorkspaceDetails/:parentId", element: <SubWorkDetails /> },
      { path: "/newdataconnection/:parentId", element: <NewDataConnection /> },
      { path: "/SubWorkspaceDetails/:parentId", element: <SubWorkDetails /> },
      { path: "/newdataset/:parentId", element: <NewDataSet /> },
      { path: "/editdataset/:parentId", element: <EditDataSet /> },
      { path: "/dataviewer/:parentId", element: <DataViewer /> },
      { path: "/flatfileupload/:parentId", element: <FlatFileUpload /> },
      { path: "/editflatfile/:parentId", element: <EditFlatFileData /> },
    ];

    // Routes accessible by 'admin' and 'account_admin'
    const adminRoutes = [
      { path: "/admin/users", element: <AdminUser /> },
      { path: "/admin/groups", element: <Groups /> },
      { path: "/group-details/:groupId", element: <GroupDetails /> },
      { path: "/users/add", element: <AddUser /> },
      // { path: "/admin/admins", element: <Admins /> },
    ];

    // Routes accessible only by 'account_admin'
    const accountAdminRoutes = [
      // { path: "/users/add", element: <AddUser /> },
      { path: "/admin/admins", element: <Admins /> },
    ];

    let routesToRender = [...commonRoutes];

    if (access === "account_admin") {
      routesToRender = [
        ...routesToRender,
        ...adminRoutes,
        ...accountAdminRoutes,
      ];
    } else if (access === "admin") {
      routesToRender = [...routesToRender, ...adminRoutes];
      // 'admin' cannot access '/admin/admins'
    }
    // 'user' role does not get additional routes beyond commonRoutes

    return routesToRender.map((route, index) => (
      <Route key={index} path={route.path} element={route.element} />
    ));
  };

  return (
    <>
      <BackButtonHandler />
      <Routes>
        {renderRoutes()}
        {/* Optionally, add a catch-all route for 404 */}
        <Route path="*" element={<AccessRestricted />} />
      </Routes>
    </>
  );
};

// Map Redux state to component props
const mapStateToProps = (state: any) => {
  return {
    isUserLogged: state.isLogged.isUserLogged,
    access: state.isLogged.access, // Map 'access' from Redux state
  };
};

// Map dispatch to props
const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    userAuthentication: (payload: any) => dispatch(userAuthentication(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
