"use client";
import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import useSWR from "swr";
import styles from "./adminlist.module.css";
import { Link } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { Dialog, Tooltip, AlertColor } from "@mui/material";
import { NotificationDialog } from "./CommonFunctions/DialogComponents";
import { useNavigate } from "react-router-dom";
import { ffButtonStyle } from "../Components/Controls/muiStyles";
import HoverButton from "./Buttons/HoverButton";

import {
  localEndPoint,
  serverEndPoint,
} from "./ServerCall/EnvironmentVariables";

import FetchData from "./ServerCall/FetchData";
import { fontSize, palette } from "..";

const axiosInstance = axios.create({
  headers: {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    "X-TENANT-ID": "silzila",
    "Content-type": "application/json",
  },
});

const AdminList = () => {
  const navigate = useNavigate();
  const [showAddAdminDialog, setShowAddAdminDialog] = useState(false);
  const [modalSelectedUsers, setModalSelectedUsers] = useState<string | null>(
    null
  ); // Store single ID
  const [activeTab, setActiveTab] = useState("users");
  const [modalSelectedGroups, setModalSelectedGroups] = useState<string | null>(
    null
  ); // Store single ID
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false);
  const [userIndexToRemove, setUserIndexToRemove] = useState<string | null>(
    null
  );
  const [adminToRemove, setAdminToRemove] = useState<any>(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [userSearchQuery, setUserSearchQuery] = useState("");
  const [groupSearchQuery, setGroupSearchQuery] = useState("");
  const [showTransferAdminDialog, setShowTransferAdminDialog] = useState(false);
  const [selectedTransferUserId, setSelectedTransferUserId] = useState<
    string | null
  >(null);
  const [showConfirmTransferDialog, setShowConfirmTransferDialog] =
    useState(false);
  const [hoveredRow, setHoveredRow] = useState<number | null>(null);
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [testMessage, setTestMessage] = useState<string>("");
  const [severity, setSeverity] = useState<AlertColor>("success");
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: "asc" | "desc";
  }>({ key: "firstName", direction: "asc" });
  // ({ key: "adminName", direction: "asc" });

  const [admins, setAdmins] = useState<any[]>([]);
  const [adminsError, setAdminsError] = useState<string | null>(null);
  const [isAdminsLoading, setIsAdminsLoading] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [allUsers, setAllUsers] = useState<any[]>([]);
  const [allGroups, setAllGroups] = useState<any[]>([]);
  const [usersError, setUsersError] = useState<string | null>(null);
  const [groupsError, setGroupsError] = useState<string | null>(null);
  const [isUsersLoading, setIsUsersLoading] = useState<boolean>(true);
  const [isGroupsLoading, setIsGroupsLoading] = useState<boolean>(false);
  const [sortField, setSortField] = useState<{
    key: string;
    direction: "asc" | "desc";
  }>({ key: "name", direction: "asc" });

  // Define a fetcher function
  // const fetcher = (url: string) =>
  //   axios
  //     .get(url, {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  //         "Content-Type": "application/json",
  //       },
  //     })
  //     .then((res) => res.data);

  //   // Use SWR for admins
  //   const {
  //     data: admins = [],
  //     error: adminsError,
  //     isValidating: isAdminsLoading,
  //     mutate: mutateAdmins,
  //   } = useSWR("https://dev.silzila.com/api/admin/list", fetcher);

  const fetchAdmins = async () => {
    try {
      setIsLoading(true);
      const response = await FetchData({
        requestType: "noData",
        method: "GET",
        url: "admin/list",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          // "X-TENANT-ID": "silzila",
        },
      });

      if (response.status) {
        return response.data;
      } else {
        throw new Error(response.data.detail || "Failed to fetch admins");
      }
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    const getAdmins = async () => {
      setIsAdminsLoading(true);
      try {
        const data = await fetchAdmins();
        setAdmins(data);
        setAdminsError(null);
      } catch (error: any) {
        setAdminsError(error.message || "An error occurred");
      } finally {
        setIsAdminsLoading(false);
      }
    };

    getAdmins();
  }, []);

  const filteredAdmins: any = admins
    .filter((admin: any) =>
      admin.name.toLowerCase().startsWith(searchQuery.toLowerCase())
    )

    .sort((a: any, b: any) => {
      if (a.roleName === "account_admin") return -1;
      if (b.roleName === "account_admin") return 1;
      let aValue, bValue;

      if (sortConfig.key === "name") {
        aValue = a.name.toLowerCase();
        bValue = b.name.toLowerCase();
      } else if (sortConfig.key === "email") {
        aValue = a.emailId;
        bValue = b.emailId;
      }

      if (aValue < bValue) {
        return sortConfig.direction === "asc" ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === "asc" ? 1 : -1;
      }
      return 0;
    });

  // Extract admin user and group IDs
  const adminUserIds = useMemo(
    () =>
      admins
        .filter((admin: any) => !admin.isGroup)
        .map((admin: any) => admin.id),
    [admins]
  );
  const adminGroupIds = useMemo(
    () =>
      admins
        .filter((admin: any) => admin.isGroup)
        .map((admin: any) => admin.id),
    [admins]
  );

  // // Use SWR for users
  // const {
  //   data: allUsers = [],
  //   error: usersError,
  //   isValidating: isUsersLoading,
  // } = useSWR(
  //   activeTab === "users" ? "https://dev.silzila.com/api/users/list" : null,
  //   fetcher
  // );

  // // Use SWR for groups
  // const {
  //   data: allGroups = [],
  //   error: groupsError,
  //   isValidating: isGroupsLoading,
  // } = useSWR(
  //   activeTab === "groups" ? "https://dev.silzila.com/api/allgroups" : null,
  //   fetcher
  // );

  const fetchUsers = async () => {
    try {
      setIsUsersLoading(true);
      const response = await FetchData({
        requestType: "noData",
        method: "GET",
        url: "users/list",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          // "X-TENANT-ID": "silzila",
        },
      });

      if (response.status) {
        return response.data; // Return user data if successful
      } else {
        throw new Error(response.data.detail || "Failed to fetch users");
      }
    } catch (error) {
      throw error;
    } finally {
      setIsUsersLoading(false);
    }
  };

  const fetchGroups = async () => {
    try {
      setIsGroupsLoading(true);
      const response = await FetchData({
        requestType: "noData",
        method: "GET",
        url: "allgroups",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          // "X-TENANT-ID": "silzila",
        },
      });

      if (response.status) {
        return response.data; // Return group data if successful
      } else {
        throw new Error(response.data.detail || "Failed to fetch groups");
      }
    } catch (error) {
      throw error;
    } finally {
      setIsGroupsLoading(false);
    }
  };

  useEffect(() => {
    const getUsers = async () => {
      if (activeTab === "users") {
        setIsUsersLoading(true);
        try {
          const data = await fetchUsers();
          setAllUsers(data);
          setUsersError(null); // Clear any existing error
        } catch (error: any) {
          setUsersError(
            error.message || "An error occurred while fetching users"
          );
        } finally {
          setIsUsersLoading(false);
        }
      }
    };

    getUsers();
  }, [activeTab]); // Re-fetch users when `activeTab` changes to "users"

  useEffect(() => {
    const getGroups = async () => {
      if (activeTab === "groups") {
        setIsGroupsLoading(true);
        try {
          const data = await fetchGroups();
          setAllGroups(data);
          setGroupsError(null); // Clear any existing error
        } catch (error: any) {
          setGroupsError(
            error.message || "An error occurred while fetching groups"
          );
        } finally {
          setIsGroupsLoading(false);
        }
      }
    };

    getGroups();
  }, [activeTab]); // Re-fetch groups when `activeTab` changes to "groups"

  // Optional: State to store users with profile images
  const [usersWithImages, setUsersWithImages] = useState<any[]>([]);

  // Filter only users from admins (exclude groups)
  const adminUsers = useMemo(
    () => admins.filter((admin: any) => !admin.isGroup),
    [admins]
  );

  // Function to open transfer admin dialog
  const openTransferAdminDialog = () => {
    setShowTransferAdminDialog(true);
    setSelectedTransferUserId(null); // Reset selection
  };

  const showAlert = (message: string, severity: AlertColor) => {
    setTestMessage(message);
    setSeverity(severity);
    setOpenAlert(true);
    setTimeout(() => {
      setOpenAlert(false);
    }, 3000);
  };

  // Function to handle transfer confirmation
  // const handleTransferAdminConfirm = async () => {
  //   if (!selectedTransferUserId) {
  //     showAlert("No user selected to transfer.", "info");
  //     return;
  //   }

  //   try {
  //     const response = await axios.post(
  //       "https://dev.silzila.com/api/account-admin/transistion",
  //       {
  //         granteeId: selectedTransferUserId,
  //         isGroup: false,
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );

  //     console.log("Transfer Admin API Response:", response);
  //     if (response.status === 200) {
  //       showAlert(
  //         "Account Admin rights transferred successfully. You will be logged out. Please login again.",
  //         "success"
  //       );
  //       setShowTransferAdminDialog(false);
  //       setShowConfirmTransferDialog(false);
  //       mutateAdmins(); // Refresh admin list after transfer
  //       // Navigate to login page after transfer
  //       navigate("/Login");
  //     } else {
  //       showAlert(
  //         response.data.message || "Failed to transfer admin.",
  //         "error"
  //       );
  //       console.error("Failed to transfer admin:", response.data);
  //     }
  //   } catch (error: any) {
  //     if (error.response) {
  //       const errorMessage =
  //         error.response.data.message ||
  //         "An error occurred while transferring admin.";
  //       showAlert(errorMessage, "error");
  //     } else if (error.request) {
  //       showAlert("No response from server. Please try again later.", "error");
  //     } else {
  //       showAlert(error.message || "Unexpected error occurred.", "error");
  //     }
  //   } finally {
  //     setOpenAlert(true);
  //     setShowTransferAdminDialog(false);
  //     setShowConfirmTransferDialog(false);
  //   }
  // };

  const handleTransferAdminConfirm = async () => {
    if (!selectedTransferUserId) {
      showAlert("No user selected to transfer.", "info");
      return;
    }

    setIsAdminsLoading(true);
    try {
      const response = await FetchData({
        requestType: "withData",
        method: "POST",
        url: "account-admin/transistion",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "Content-Type": "application/json",
        },
        data: {
          granteeId: selectedTransferUserId,
          isGroup: false,
        },
      });

      console.log("Transfer Admin API Response:", response);
      if (response.status) {
        showAlert(
          "Account Admin rights transferred successfully. You will be logged out. Please login again.",
          "success"
        );
        setShowTransferAdminDialog(false);
        setShowConfirmTransferDialog(false);
        fetchAdmins();
        // navigate("/Login");
        navigate(`${localEndPoint}auth/business/signin`);
      } else {
        showAlert(
          response.data.message || "Failed to transfer admin.",
          "error"
        );
        console.error("Failed to transfer admin:", response.data);
      }
    } catch (error: any) {
      if (error.response) {
        const errorMessage =
          error.response.data.message ||
          "An error occurred while transferring admin.";
        showAlert(errorMessage, "error");
      } else if (error.request) {
        showAlert("No response from server. Please try again later.", "error");
      } else {
        showAlert(error.message || "Unexpected error occurred.", "error");
      }
    } finally {
      setIsAdminsLoading(false);
      setOpenAlert(true);
      setShowTransferAdminDialog(false);
      setShowConfirmTransferDialog(false);
    }
  };

  const handleSort = (key: any) => {
    const newDirection =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key, direction: newDirection });
  };

  const getSortIcon = (key: any) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "asc" ? (
        <img className="headerIcon-up" src="/sort-up.png" alt="Ascending" />
      ) : (
        <img
          className="headerIcon-down"
          src="/sort-down.png"
          alt="Descending"
        />
      );
    }
    return <img className="headerIcon" src="/sort.png" alt="Default" />;
  };

  // useEffect(() => {
  //   const fetchProfileImages = async () => {
  //     if (allUsers.length > 0) {
  //       const updatedUsers = await Promise.all(
  //         allUsers.map(async (user: any) => {
  //           try {
  //             const imageResponse = await axiosInstance.get(
  //               `https://dev.silzila.com/api/user/profile-image`,
  //               {
  //                 params: { userId: user.id },
  //               }
  //             );

  //             let profileImageUrl;
  //             if (imageResponse.data && user.profileImage) {
  //               profileImageUrl = `data:image/jpeg;base64,${user.profileImage}`;
  //             } else {
  //               profileImageUrl = "/default.png";
  //             }
  //             return { ...user, profileImage: profileImageUrl };
  //           } catch (error) {
  //             console.error(
  //               `Failed to fetch image for user ${user.id}:`,
  //               error
  //             );
  //             showAlert(`Failed to fetch image for user ${user.id}:`, "error");
  //             return { ...user, profileImage: "/default.png" };
  //           }
  //         })
  //       );
  //       setUsersWithImages(updatedUsers);
  //     }
  //   };

  //   fetchProfileImages();
  // }, [allUsers]);

  useEffect(() => {
    let isMounted = true;

    const fetchProfileImages = async () => {
      if (!allUsers || allUsers.length === 0) return;

      try {
        // Filter users who already have a profileImage
        const usersToFetch = allUsers.filter(
          (user: { profileImage: any; isAdmin: boolean }) =>
            user.profileImage && user.isAdmin
        );

        if (usersToFetch.length === 0) {
          setUsersWithImages(allUsers);
          return;
        }

        const updatedUsers = await Promise.all(
          allUsers.map(async (user: any) => {
            try {
              const imageResponse = await FetchData({
                requestType: "withData",
                method: "GET",
                url: "user/profile-image",
                data: { userId: user.id },
                headers: {
                  Authorization: `Bearer ${localStorage.getItem(
                    "accessToken"
                  )}`,
                  "Content-Type": "application/json",
                },
              });

              let profileImageUrl;
              if (imageResponse.data && user.profileImage) {
                profileImageUrl = `data:image/jpeg;base64,${user.profileImage}`;
              } else {
                profileImageUrl = "/default.png";
              }
              return { ...user, profileImage: profileImageUrl };
            } catch (error) {
              console.error(
                `Failed to fetch image for user ${user.id}:`,
                error
              );
              showAlert(`Failed to fetch image for user ${user.id}:`, "error");
              return { ...user, profileImage: "/default.png" };
            }
          })
        );
        if (isMounted) {
          // Merge the updated users back with the rest of the users
          const finalUsers = allUsers.map(
            (user: any) => updatedUsers.find((u) => u.id === user.id) || user
          );
          setUsersWithImages(updatedUsers);
        }
      } catch (error) {
        console.error("Failed to fetch profile images:", error);
      }

      // fetchProfileImages();

      return () => {
        isMounted = false;
      };
    };
    fetchProfileImages();
  }, [allUsers]);

  const filteredUsers = useMemo(() => {
    if (!usersWithImages) return []; // Return an empty array if no users
    return usersWithImages.filter(
      (user: any) =>
        !adminUserIds.includes(user.id) && // Exclude already admins
        !user.isDeleted && // Exclude Deleted Users
        (user.firstName.toLowerCase().includes(userSearchQuery.toLowerCase()) ||
          user.lastName.toLowerCase().includes(userSearchQuery.toLowerCase()) ||
          user.email.toLowerCase().includes(userSearchQuery.toLowerCase()))
    );
  }, [usersWithImages, userSearchQuery, adminUserIds]);

  const filteredGroups = useMemo(() => {
    if (!allGroups || !allUsers) return []; // Return an empty array if no groups
    return allGroups.filter(
      (group: any) =>
        !adminGroupIds.includes(group.id) && // Exclude already admin groups
        group.groupName
          .toLowerCase()
          .includes(groupSearchQuery.toLowerCase()) &&
        group.userCount !== allUsers.length
    );
  }, [allGroups, groupSearchQuery, adminGroupIds, !allUsers]);

  const toggleModalSelectUser = (userId: string) => {
    setModalSelectedUsers((prevSelected) =>
      prevSelected === userId ? null : userId
    );
  };

  const toggleModalSelectGroup = (groupId: string) => {
    setModalSelectedGroups((prevSelected) =>
      prevSelected === groupId ? null : groupId
    );
  };

  const toggleUserSelection = (userId: string) => {
    setSelectedUserIds((prevSelected) =>
      prevSelected.includes(userId)
        ? prevSelected.filter((id) => id !== userId)
        : [...prevSelected, userId]
    );
  };

  // const confirmRemoveUser = async () => {
  //   try {
  //     const adminToRemove: any = admins.find(
  //       (admin: any) => admin.id === userIndexToRemove
  //     );
  //     if (!adminToRemove) {
  //       console.error("Admin not found for deletion.");
  //       showAlert("Admin not found for deletion.", "warning");
  //       return;
  //     }

  //     const response = await axios.delete(
  //       `https://dev.silzila.com/api/admin/delete`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  //           "Content-Type": "application/json",
  //         },
  //         data: {
  //           granteeId: adminToRemove.id,
  //           isGroup: adminToRemove.isGroup || false, // Ensure correct type
  //         },
  //       }
  //     );
  //     mutateAdmins();
  //     if (response.status === 200) {
  //       showAlert(
  //         response.data.message || "Admin removed successfully.",
  //         "success"
  //       );
  //       mutateAdmins(); // Refresh admin list after removal
  //     } else {
  //       showAlert(response.data.message || "Failed to remove admin.", "error");
  //     }
  //   } catch (error: any) {
  //     if (error.response) {
  //       showAlert(
  //         error.response.data.message || "Error removing admin.",
  //         "error"
  //       );
  //       console.error("Axios Error - Response:", error.response);
  //     } else if (error.request) {
  //       showAlert(
  //         "No response received from server. Please try again later.",
  //         "error"
  //       );
  //       console.error("Axios Error - No Response Received:", error.request);
  //     } else {
  //       showAlert(error.message || "Unexpected error occurred.", "error");
  //       console.error("Axios Error - Other:", error.message);
  //     }
  //   } finally {
  //     setOpenAlert(true);
  //     setShowRemoveConfirmation(false);
  //     setUserIndexToRemove(null);
  //   }
  // };
  const confirmRemoveUser = async () => {
    try {
      const adminToRemove: any = admins.find(
        (admin: any) => admin.id === userIndexToRemove
      );
      if (!adminToRemove) {
        console.error("Admin not found for deletion.");
        showAlert("Admin not found for deletion.", "warning");
        return;
      }

      setIsAdminsLoading(true);
      setShowRemoveConfirmation(false);
      const response = await FetchData({
        requestType: "withData",
        method: "DELETE",
        url: "admin/delete",
        data: {
          granteeId: adminToRemove.id,
          isGroup: adminToRemove.isGroup || false,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "Content-Type": "application/json",
        },
      });

      try {
        const data = await fetchAdmins();
        setAdmins(data);
        setAdminsError(null);
      } catch (error: any) {
        setAdminsError(error.message || "An error occurred");
      } finally {
        setIsAdminsLoading(false);
      } // Refresh admin list after removal

      if (response.status) {
        showAlert(
          response.data.message || "Admin removed successfully.",
          "success"
        );
      } else {
        showAlert(response.data.message || "Failed to remove admin.", "error");
      }
    } catch (error: any) {
      if (error.response) {
        showAlert(
          error.response.data.message || "Error removing admin.",
          "error"
        );
        console.error("FetchData Error - Response:", error.response);
      } else if (error.request) {
        showAlert(
          "No response received from server. Please try again later.",
          "error"
        );
        console.error("FetchData Error - No Response Received:", error.request);
      } else {
        showAlert(error.message || "Unexpected error occurred.", "error");
        console.error("FetchData Error - Other:", error.message);
      }
    } finally {
      setIsAdminsLoading(false);
      setOpenAlert(true);
      setShowRemoveConfirmation(false);
      setUserIndexToRemove(null);
    }
  };

  const removeUser = (adminId: string) => {
    setUserIndexToRemove(adminId);
    const admin = admins.find((admin: any) => admin.id === adminId);
    setAdminToRemove(admin);
    setShowRemoveConfirmation(true);
  };

  const cancelRemove = () => {
    setShowRemoveConfirmation(false);
    setUserIndexToRemove(null);
  };

  // const handleAddAdminConfirm = async () => {
  //   try {
  //     const isGroup = activeTab === "groups";
  //     const selectedId = isGroup ? modalSelectedGroups : modalSelectedUsers;

  //     if (!selectedId) {
  //       const infoMessage = isGroup
  //         ? "No group selected to add as admin."
  //         : "No user selected to add as admin.";
  //       showAlert(infoMessage, "info");
  //       return;
  //     }

  //     console.log("Selected ID:", selectedId, "Is Group:", isGroup);

  //     const response = await axios.post(
  //       "https://dev.silzila.com/api/user/admin",
  //       {
  //         granteeId: selectedId,
  //         isGroup: isGroup,
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );

  //     console.log("Response Status:", response.status);
  //     console.log("Response Data:", response.data);

  //     if (response.status === 201) {
  //       setShowAddAdminDialog(false);
  //       setModalSelectedUsers(null);
  //       setModalSelectedGroups(null);
  //       setSearchQuery("");
  //       setGroupSearchQuery("");
  //       mutateAdmins();
  //       showAlert("Admin added Successfully!", "success");
  //     } else {
  //       console.error("Failed to add admin. Response data:", response.data);
  //       setModalSelectedUsers(null);
  //       setModalSelectedGroups(null);
  //       mutateAdmins();
  //       showAlert(response.data.message || "Failed to add admin", "error");
  //     }
  //   } catch (error: any) {
  //     if (error.response) {
  //       console.error("Axios Error - Response:", error.response);
  //     } else if (error.request) {
  //       console.error("Axios Error - No Response Received:", error.request);
  //     } else {
  //       console.error("Axios Error - Other:", error.message);
  //     }
  //   }
  // };

  const handleAddAdminConfirm = async () => {
    try {
      const isGroup = activeTab === "groups";
      const selectedId = isGroup ? modalSelectedGroups : modalSelectedUsers;

      if (!selectedId) {
        const infoMessage = isGroup
          ? "No group selected to add as admin."
          : "No user selected to add as admin.";
        showAlert(infoMessage, "info");
        return;
      }

      console.log("Selected ID:", selectedId, "Is Group:", isGroup);

      setIsAdminsLoading(true);
      const response = await FetchData({
        requestType: "withData",
        method: "POST",
        url: "user/admin",
        data: {
          granteeId: selectedId,
          isGroup: isGroup,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "Content-Type": "application/json",
        },
      });

      console.log("Response Status:", response.status);
      console.log("Response Data:", response.data);

      if (response.status) {
        setShowAddAdminDialog(false);
        setModalSelectedUsers(null);
        setModalSelectedGroups(null);
        setUserSearchQuery("");
        setGroupSearchQuery("");
        try {
          const data = await fetchAdmins();
          setAdmins(data);
          setAdminsError(null);
        } catch (error: any) {
          setAdminsError(error.message || "An error occurred");
        } finally {
          setIsAdminsLoading(false);
        }
        showAlert("Admin added Successfully!", "success");
      } else {
        console.error("Failed to add admin. Response data:", response.data);
        setModalSelectedUsers(null);
        setModalSelectedGroups(null);
        try {
          const data = await fetchAdmins();
          setAdmins(data);
          setAdminsError(null);
        } catch (error: any) {
          setAdminsError(error.message || "An error occurred");
        } finally {
          setIsAdminsLoading(false);
        }
        showAlert(response.data.message || "Failed to add admin", "error");
      }
    } catch (error: any) {
      if (error.response) {
        console.error("FetchData Error - Response:", error.response);
      } else if (error.request) {
        console.error("FetchData Error - No Response Received:", error.request);
      } else {
        console.error("FetchData Error - Other:", error.message);
      }
    } finally {
      setIsAdminsLoading(false);
    }
  };
  const sortedAndFilteredUsers = React.useMemo(() => {
    let sortedItems = [...filteredUsers];
    if (sortField.key) {
      sortedItems.sort((a, b) => {
        let aValue, bValue;
        if (sortField.key === "name") {
          aValue = `${a.firstName} ${a.lastName}`.toLowerCase();
          bValue = `${b.firstName} ${b.lastName}`.toLowerCase();
        } else if (sortField.key === "email") {
          aValue = a.email ? a.email.toLowerCase() : "";
          bValue = b.email ? b.email.toLowerCase() : "";
        }
        if (aValue < bValue) {
          return sortField.direction === "asc" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortField.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortedItems;
  }, [filteredUsers, sortField]);

  const sortedAndFilteredGroups = React.useMemo(() => {
    let sortedItems = [...filteredGroups];
    if (sortField.key) {
      sortedItems.sort((a, b) => {
        let aValue, bValue;
        if (sortField.key === "groupName") {
          aValue = a.groupName.toLowerCase();
          bValue = b.groupName.toLowerCase();
        } else if (sortField.key === "userCount") {
          aValue = a.userCount;
          bValue = b.userCount;
        }
        if (aValue < bValue) {
          return sortField.direction === "asc" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortField.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortedItems;
  }, [filteredGroups, sortField]);

  const handleSortModal = (key: string) => {
    setSortField((prev) => ({
      key,
      direction: prev.key === key && prev.direction === "asc" ? "desc" : "asc",
    }));
  };

  const getSortIconModal = (key: any) => {
    if (sortField.key === key) {
      return sortField.direction === "asc" ? (
        <img
          className="headerIcon-up-modal"
          src="/sort-up.png"
          alt="Ascending"
        />
      ) : (
        <img
          className="headerIcon-down-modal"
          src="/sort-down.png"
          alt="Descending"
        />
      );
    }
    return <img className="headerIcon-modal" src="/sort.png" alt="Default" />;
  };

  const resetModalState = () => {
    setModalSelectedUsers(null);
    setModalSelectedGroups(null);
    setUserSearchQuery("");
    setGroupSearchQuery("");
  };
  if (adminsError)
    return (
      <div className="loading-error-info">
        <p
          className="loading-default-text"
          style={{
            fontSize: fontSize.medium,
            color: palette.primary.contrastText,
          }}
        >
          Error loading admins.
        </p>
      </div>
    );
  if (usersError)
    return (
      <div className="loading-error-info">
        <p
          className="loading-default-text"
          style={{
            fontSize: fontSize.medium,
            color: palette.primary.contrastText,
          }}
        >
          Error loading users.
        </p>
      </div>
    );
  if (groupsError)
    return (
      <div className="loading-error-info">
        <p
          className="loading-default-text"
          style={{
            fontSize: fontSize.medium,
            color: palette.primary.contrastText,
          }}
        >
          Error loading groups.
        </p>
      </div>
    );

  return (
    <div className={`${styles.adminContainer} admin-container`}>
      <div
        className="heading"
        style={{
          height: "50px",
          border: "1px solid transparent",
        }}
      >
        <img
          src="/admin.png"
          alt="Admins"
          style={{ width: "22px", height: "22px" }}
        />
        <div>
          <h3
            style={{
              // marginTop: "-4px",
              // marginBottom: "5px",
              padding: "0px",
              margin: "0px",
              marginLeft: "6px",
              fontSize: fontSize.large,
              color: palette.primary.contrastText,
            }}
          >
            Admins
          </h3>
        </div>
      </div>

      {/*  <div className={styles.adminBackLinkContainer}>          
            <button className={styles.backLinkAdmin}>
            <Link to='/'><button className='back-link-user'>Back</button></Link>            
            </button>
          </div>  */}

      <div className="adminButtonAddSearchContainer">
        <div className="adminActionButtons">
          <button
            className={`${styles.adminAddButton} admin-addButton`}
            onClick={() => setShowAddAdminDialog(true)}
            style={{ fontSize: fontSize.medium }}
          >
            <img
              className={`${styles.adminAddIconDefault} addIconDefault`}
              src="/add_purple.png"
              alt="Add Admin Logo"
              style={{ width: "16px", height: "16px" }}
            />
            <img
              className={`${styles.adminAddIconHover} addIconHover`}
              src="/add_white.png"
              alt="Add Admin Logo Hover"
              style={{ width: "16px", height: "16px" }}
            />
            Add Admin
          </button>
        </div>

        <div className="adminSelectActionContainer">
          <div className="adminActionsContainer"></div>

          <div className="adminSearchContainer">
            <input
              style={{
                fontSize: fontSize.medium,
                color: palette.primary.contrastText,
              }}
              type="text"
              placeholder="Search Admins"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="adminSearchInput"
            />
            <img
              src="/glass.png"
              alt="Search Icon"
              className="adminSearchIcon"
            />
          </div>
        </div>
      </div>

      <>
        {isAdminsLoading ? ( //|| isUsersLoading || isGroupsLoading
          <div className="loading-container">
            <div className="spinner"></div>
          </div>
        ) : admins.length === 0 && searchQuery.length === 0 ? (
          <div className="no-user-info">
            <p
              className="loading-default-text"
              style={{
                fontSize: fontSize.medium,
                color: palette.primary.contrastText,
              }}
            >
              Currently, there are no Admins available.
            </p>
          </div>
        ) : (
          <div className="adminTableContainer">
            <table className={`${styles.adminTable} admin-table`}>
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th onClick={() => handleSort("name")}>
                    Name{" "}
                    <span className="icon-wrapper">{getSortIcon("name")}</span>
                  </th>
                  <th onClick={() => handleSort("email")}>
                    Email{" "}
                    <span className="icon-wrapper">{getSortIcon("email")}</span>
                  </th>
                  <th>Actions</th>
                </tr>
              </thead>

              {filteredAdmins.length === 0 && searchQuery.length !== 0 ? (
                <div className="no-user-info-insideTable">
                  <p
                    className="loading-default-text"
                    style={{
                      fontSize: fontSize.medium,
                      color: palette.primary.contrastText,
                    }}
                  >
                    No Admins Found.
                  </p>
                </div>
              ) : (
                <tbody>
                  {filteredAdmins.map((admin: any) => (
                    <tr
                      key={admin.id}
                      onMouseEnter={() => setHoveredRow(admin.id)}
                      onMouseLeave={() => setHoveredRow(null)}
                    >
                      <td>
                        {/* <input
                        type="checkbox"
                        checked={selectedUserIds.includes(admin.id)}
                        onChange={() => toggleUserSelection(admin.id)}
                        style={{ width: "16px", height: "16px" }}
                      /> */}
                      </td>

                      <td>
                        <img
                          src={
                            admin.isGroup
                              ? "/groups.png"
                              : admin.profileImage
                              ? `data:image/jpeg;base64,${admin.profileImage}`
                              : "/default.png"
                          }
                          style={{
                            width: "20px",
                            height: "20px",
                            borderRadius: "50%",
                          }}
                          alt={admin.isGroup ? "Group Icon" : "User Profile"}
                        />
                      </td>
                      <td>
                        {admin.roleName === "account_admin" ? (
                          <Tooltip title="Account Admin">
                            <img
                              src="/crown-admin.png"
                              alt="Admin Icon"
                              style={{
                                transform: "translateY(3px)",
                                width: "15px",
                                height: "15px",
                                marginRight: "15px",
                              }}
                            />
                          </Tooltip>
                        ) : (
                          <span
                            style={{
                              display: "inline-block",
                              width: "15px",
                              height: "15px",
                              marginRight: "15px",
                            }}
                          ></span>
                        )}
                        {admin.isGroup ? (
                          <Link
                            to={`/group-details/${admin.id}`}
                            style={{
                              textDecoration: "none",
                              color: "inherit",
                              fontSize: "13px",
                            }}
                            onMouseEnter={(e) => {
                              const target = e.target as HTMLElement;
                              target.style.textDecoration = "underline";
                              target.style.color = "#2bb9bb";
                            }}
                            onMouseLeave={(e) => {
                              const target = e.target as HTMLElement;
                              target.style.textDecoration = "none";
                              target.style.color = "#616164";
                            }}
                          >
                            {admin.name}
                          </Link>
                        ) : (
                          admin.name
                        )}
                      </td>

                      <td>{admin.isGroup ? "-" : admin.emailId}</td>
                      <td>
                        {admin.roleName === "account_admin" ? (
                          <button
                            onClick={openTransferAdminDialog}
                            style={{
                              background: "none",
                              border: "none",
                              padding: 0,
                              cursor: "pointer",
                              marginLeft: "5px",
                              marginRight: "5px",
                            }}
                          >
                            <Tooltip title="Transfer Account Admin">
                              <img
                                src={
                                  hoveredRow === admin.id
                                    ? "/transfer.png"
                                    : "/transfer_grey.png"
                                }
                                alt="Transfer Admin"
                                style={{ width: "17px", height: "17px" }}
                              />
                            </Tooltip>
                          </button>
                        ) : (
                          <button
                            onClick={() => removeUser(admin.id)}
                            style={{
                              background: "none",
                              border: "none",
                              padding: 0,
                              cursor: "pointer",
                              marginLeft: "5px",
                              marginRight: "5px",
                            }}
                          >
                            <Tooltip title="Remove Admin">
                              <img
                                src={
                                  hoveredRow === admin.id
                                    ? "/cross_red.png"
                                    : "/cross.png"
                                }
                                alt="Remove Admin"
                                style={{ width: "17px", height: "17px" }}
                              />
                            </Tooltip>
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          </div>
        )}
      </>

      <Dialog
        open={showAddAdminDialog}
        onClose={() => {
          setShowAddAdminDialog(false);
          resetModalState();
        }}
        maxWidth="md"
      >
        <div className="adminListModal">
          <div className="adminListModalContent">
            <div className="adminListHeaderSection">
              <div className="adminListHeaderClose">
                <Tooltip title="Close">
                  <CloseIcon
                    onClick={() => {
                      setShowAddAdminDialog(false);
                      resetModalState();
                    }}
                    sx={{
                      fontSize: "large",
                      color: palette.primary.contrastText,
                      cursor: "pointer",
                      "&:hover": {
                        color: "red",
                      },
                    }}
                  />
                </Tooltip>
              </div>

              <h3
                className="admin-modal-title"
                style={{
                  fontSize: fontSize.large,
                  color: palette.primary.contrastText,
                }}
              >
                {activeTab === "users"
                  ? "Select User to Add as Admin"
                  : "Select Group to Add as Admin"}
              </h3>
              <div className={styles.adminListModalTabs}>
                {/* Tab Switch */}
                <div className={styles.tabContainer}>
                  <div
                    onClick={() => setActiveTab("users")}
                    className={`${styles.tab} ${
                      activeTab === "users" ? styles.activeTab : ""
                    }`}
                    style={{ fontSize: fontSize.medium }}
                  >
                    Users
                  </div>
                  <div
                    onClick={() => setActiveTab("groups")}
                    style={{ fontSize: fontSize.medium }}
                    className={`${styles.tab} ${
                      activeTab === "groups" ? styles.activeTab : ""
                    }`}
                  >
                    Groups
                  </div>
                </div>
              </div>

              {activeTab === "users" && (
                <div className="adminListSearchContainer">
                  {/* User Search */}
                  <input
                    type="text"
                    placeholder="Search users"
                    value={userSearchQuery}
                    onChange={(e) => setUserSearchQuery(e.target.value)}
                    className="adminListSearchInput"
                  />
                  <img
                    src="/glass.png"
                    alt="Search Icon"
                    className="adminListSearchIcon"
                  />
                </div>
              )}
              {activeTab === "groups" && (
                <div className="adminListSearchContainer">
                  {/* Group Search */}
                  <input
                    type="text"
                    placeholder="Search groups"
                    value={groupSearchQuery}
                    onChange={(e) => setGroupSearchQuery(e.target.value)}
                    className="adminListSearchInput"
                  />
                  <img
                    src="/glass.png"
                    alt="Search Icon"
                    className="adminListSearchIcon"
                  />
                </div>
              )}
            </div>

            <div className="adminListTableContainer">
              {activeTab === "users" && (
                <table
                  className={`${styles.adminListUserList} adminListUserList`}
                >
                  <thead>
                    <tr>
                      <th></th>
                      <th></th>
                      <th onClick={() => handleSortModal("name")}>
                        Name{" "}
                        <span className="icon-wrapper-modal">
                          {getSortIconModal("name")}
                        </span>
                      </th>
                      <th onClick={() => handleSortModal("email")}>
                        Email{" "}
                        <span className="icon-wrapper-modal">
                          {getSortIconModal("email")}
                        </span>
                      </th>
                    </tr>
                  </thead>

                  {sortedAndFilteredUsers.length === 0 &&
                  userSearchQuery.length !== 0 ? (
                    <div className="modal-content-no-user-info">
                      <p
                        className="loading-default-text"
                        style={{
                          fontSize: fontSize.medium,
                          color: palette.primary.contrastText,
                        }}
                      >
                        No user found.
                      </p>
                    </div>
                  ) : (
                    <tbody>
                      {sortedAndFilteredUsers.map((user: any) => (
                        <tr key={user.id}>
                          <td>
                            <input
                              type="radio"
                              checked={modalSelectedUsers === user.id}
                              onChange={() => toggleModalSelectUser(user.id)}
                            />
                          </td>
                          <td>
                            <img
                              // src={
                              //   user.profileImage
                              //     ? `data:image/jpeg;base64,${btoa(
                              //         new Uint8Array(user.profileImage).reduce(
                              //           (data, byte) => data + String.fromCharCode(byte),
                              //           ""
                              //         )
                              //       )}`
                              //     : "/default.png"
                              // }
                              src={
                                user.profileImage
                                  ? user.profileImage
                                  : "/default.png"
                              }
                              style={{
                                width: "20px",
                                height: "20px",
                                borderRadius: "50%",
                              }}
                              alt="User Profile"
                            />
                          </td>
                          <td>
                            {user.firstName} {user.lastName}
                          </td>
                          <td>{user.email}</td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
              )}

              {activeTab === "groups" && (
                <table
                  className={`${styles.adminListGroupList} adminListGroupList`}
                >
                  <thead>
                    <tr>
                      <th></th>
                      <th onClick={() => handleSortModal("groupName")}>
                        Group Name{" "}
                        <span className="icon-wrapper-modal">
                          {getSortIconModal("groupName")}
                        </span>
                      </th>
                      <th onClick={() => handleSortModal("userCount")}>
                        User Count{" "}
                        <span className="icon-wrapper-modal">
                          {getSortIconModal("userCount")}
                        </span>
                      </th>
                    </tr>
                  </thead>

                  {sortedAndFilteredGroups.length === 0 &&
                  groupSearchQuery.length !== 0 ? (
                    <div className="modal-content-no-user-info">
                      <p
                        className="loading-default-text"
                        style={{
                          fontSize: fontSize.medium,
                          color: palette.primary.contrastText,
                        }}
                      >
                        No group found.
                      </p>
                    </div>
                  ) : (
                    <tbody>
                      {sortedAndFilteredGroups.map((group: any) => (
                        <tr key={group.id}>
                          <td>
                            <input
                              type="radio"
                              checked={modalSelectedGroups === group.id}
                              onChange={() => toggleModalSelectGroup(group.id)}
                            />
                          </td>
                          <td>{group.groupName}</td>
                          <td>{group.userCount}</td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
              )}
            </div>

            <div className="adminListFooterSection">
              <HoverButton
                onClick={handleAddAdminConfirm}
                text="Add Admin"
                color="secondary.light"
                hoverColor="secondary.contrastText"
                backgroundColor="secondary.contastText"
                hoverBackgroundColor="secondary.light"
                sx={{
                  ...ffButtonStyle,
                  border: `1px solid ${palette.secondary.light}`,
                  fontSize: fontSize.medium,
                  lineHeight: "normal",
                  borderRadius: "5px",
                  padding: "10px 20px",
                  margin: "0",
                }}
                transitionTime="0.2s"
              />
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog open={showRemoveConfirmation} onClose={cancelRemove}>
        <div className="admin-remove-modal">
          <div className="admin-remove-modalContent-close">
            <Tooltip title="Close">
              <CloseIcon
                onClick={() => setShowRemoveConfirmation(false)}
                sx={{
                  fontSize: "large",
                  color: "#545454",
                  cursor: "pointer",
                  "&:hover": {
                    color: "red",
                  },
                }}
              />
            </Tooltip>
          </div>
          <div className="admin-remove-modalContent-p">
            <p
              style={{
                fontSize: fontSize.medium,
                color: palette.primary.contrastText,
              }}
            >
              Are you sure you want to remove {adminToRemove?.name} as admin?
            </p>
          </div>

          <div className="admin-remove-modalContent-Buttons">
            <button
              onClick={cancelRemove}
              className="admin-remove-modalCancel"
              style={{ fontSize: fontSize.medium }}
            >
              Cancel
            </button>
            <button
              onClick={confirmRemoveUser}
              className="admin-remove-modalConfirm"
              style={{ fontSize: fontSize.medium }}
            >
              Confirm
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog open={showTransferAdminDialog}>
        <div className="adminListModal">
          <div className="adminListModalContent">
            <div className="adminListHeaderSection">
              <div className="adminListHeaderClose">
                <Tooltip title="Close">
                  <CloseIcon
                    onClick={() => setShowTransferAdminDialog(false)}
                    sx={{
                      fontSize: "large",
                      color: "#545454",
                      cursor: "pointer",
                      "&:hover": {
                        color: "red",
                      },
                    }}
                  />
                </Tooltip>
              </div>

              <h3
                className="admin-modal-title"
                style={{
                  fontSize: fontSize.large,
                  color: palette.primary.contrastText,
                }}
              >
                Transfer Account Admin Account
              </h3>
              <p>Select a user to grant Account Admin privileges:</p>
            </div>

            <div className="adminListTableContainer">
              <table
                className={`${styles.adminListUserListTransfer} adminListUserListTransfer`}
              >
                <thead>
                  <tr>
                    <th></th>
                    <th>Name</th>
                    <th>Email</th>
                  </tr>
                </thead>
                <tbody>
                  {adminUsers
                    .filter(
                      (adminUser: any) => adminUser.roleName !== "account_admin"
                    ) // Exclude account_admin users
                    .map((adminUser: any) => (
                      <tr key={adminUser.id}>
                        <td>
                          <input
                            type="radio"
                            name="transferAdmin"
                            checked={selectedTransferUserId === adminUser.id}
                            onChange={() =>
                              setSelectedTransferUserId(adminUser.id)
                            }
                          />
                        </td>

                        <td>{adminUser.name}</td>
                        <td>{adminUser.emailId}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>

            <div className="adminListFooterSection" style={{ bottom: "15px" }}>
              <HoverButton
                onClick={() => {
                  if (!selectedTransferUserId) {
                    showAlert("No user selected to transfer.", "info");
                  } else {
                    setShowConfirmTransferDialog(true);
                  }
                }}
                text="Confirm Transfer"
                color="secondary.light"
                hoverColor="secondary.contrastText"
                backgroundColor="secondary.contastText"
                hoverBackgroundColor="secondary.light"
                sx={{
                  ...ffButtonStyle,
                  border: `1px solid ${palette.secondary.light}`,
                  fontSize: fontSize.medium,
                  lineHeight: "normal",
                  borderRadius: "5px",
                  padding: "10px 20px",
                  margin: "0",
                }}
                transitionTime="0.2s"
              />
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog open={showConfirmTransferDialog}>
        <div className="admin-transfer-modal">
          <div className="admin-remove-modalContent-close">
            <Tooltip title="Close">
              <CloseIcon
                onClick={() => setShowConfirmTransferDialog(false)}
                sx={{
                  fontSize: "large",
                  color: "#545454",
                  cursor: "pointer",
                  "&:hover": {
                    color: "red",
                  },
                }}
              />
            </Tooltip>
          </div>

          <div
            className="admin-transfer-modalContent-p"
            style={{
              fontSize: fontSize.medium,
            }}
          >
            <h3>Confirm Admin Transfer</h3>
            <p>
              Account Admin rights will be transferred to{" "}
              <strong>
                {
                  adminUsers.find(
                    (user: any) => user.id === selectedTransferUserId
                  )?.name
                }
              </strong>{" "}
              and you will retain admin rights."
            </p>
            <p>Please confirm you want to proceed?</p>
          </div>

          <div className="admin-transfer-modalContent-Buttons">
            <button
              className="admin-transfer-modalCancel"
              onClick={() => {
                setShowConfirmTransferDialog(false);
                setShowTransferAdminDialog(true); // Show previous dialog
              }}
              style={{
                fontSize: fontSize.medium,
              }}
            >
              Cancel
            </button>
            <button
              className="admin-transfer-modalConfirm"
              onClick={handleTransferAdminConfirm}
              style={{
                fontSize: fontSize.medium,
              }}
            >
              Confirm
            </button>
          </div>
        </div>
      </Dialog>

      <NotificationDialog
        openAlert={openAlert}
        severity={severity}
        testMessage={testMessage}
        onCloseAlert={() => {
          setOpenAlert(false);
          setTestMessage("");
        }}
      />
    </div>
  );
};

export default AdminList;
