import React, { useState, useEffect } from "react";
import styles from "./access.module.css";
import { Link, useLocation, useParams } from "react-router-dom";
import { Tooltip } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import axios from "axios";
import useSWR from "swr";
import StepSlider from "./Slider.jsx";
import { Dialog, AlertColor } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { serverEndPoint } from "./ServerCall/EnvironmentVariables";
import {
  NotificationDialog,
  AcceptRejectDialog,
} from "./CommonFunctions/DialogComponents";
import "./allPages.css";
import FetchData from "./ServerCall/FetchData";
import { fontSize, palette } from "..";
import { ffDialogTitle, ffButtonStyle } from "../Components/Controls/muiStyles";
import HoverButton from "./Buttons/HoverButton";

const fetcher = (url: string) =>
  axios
    .get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        // "X-TENANT-ID": "silzila",
      },
    })
    .then((res) => res.data);

const AccessforContents = () => {
  const [isModalOpen, setIsModalOpen] = useState<any>(false);
  const [modalContent, setModalContent] = useState<any>("User");
  const [selectedUsers, setSelectedUsers] = useState<any>([]);
  const [addedUserIds, setAddedUserIds] = useState<any>([]);
  const [editedUserIds, setEditedUserIds] = useState<any>([]);
  const contentId = useParams().parentId;
  const parentId = useLocation().state.parentId;
  const [originalAccessList, setOriginalAccessList] = useState<any>([]);
  const [revertedRows, setRevertedRows] = useState<any>([]);
  const [users, setUsers] = useState<any>([]);
  const [isReverted, setIsReverted] = useState<any>(false);
  const [customPermissions, setCustomPermissions] = useState<any>({});
  const [saveMessage, setSaveMessage] = useState(false);
  const [searchQuery, setSearchQuery] = useState<any>("");
  const [modalData, setModalData] = useState<any[]>([]);
  const [filteredModalData, setFilteredModalData] = useState<any[]>([]);
  const [modifiedUsers, setModifiedUsers] = useState<any>([]);
  const [showUserDeleteConfirmation, setShowUserDeleteConfirmation] =
    useState(false);
  const [user_GroupId, setUser_GroupID] = useState<any>();
  const [user_GroupName, setUser_GroupName] = useState<any>();
  const [userRoleName, setUserRoleName] = useState<any>();
  const [isInGroup, setIsInGroup] = useState(false);
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [testMessage, setTestMessage] = useState<string>("");
  const [severity, setSeverity] = useState<AlertColor>("success");
  const [isLoading, setIsLoading] = useState<any>(true);
  const [isReloadButtonClicked, setIsReloadButtonClicked] = useState(false);
  const [sortField, setSortField] = useState<{
    key: string;
    direction: "asc" | "desc";
  }>({ key: "name", direction: "asc" });

  const id_parent = localStorage.getItem("parentWorkspaceId");

  const navigate = useNavigate();

  const handleBackButton = () => {
    navigate(-1);
  };

  let roles: any[] = [
    { id: 3, value: "owner", displayValue: "Owner" },
    { id: 4, value: "moderator", displayValue: "Moderator" },
    { id: 5, value: "creator", displayValue: "Creator" },
    { id: 7, value: "player", displayValue: "Player" },
    { id: 6, value: "custom_creator", displayValue: "Custom Creator" },
  ];
  let contentTypes: any[] = [
    { id: 1, value: "subFolder", displayValue: "Sub Folder" },
    { id: 2, value: "dbConnection", displayValue: "DB Connection" },
    { id: 3, value: "dataset", displayValue: "Data Set" },
    { id: 4, value: "flatfile", displayValue: "Data File" },
    { id: 5, value: "playbook", displayValue: "Playbook" },
  ];

  const { data: workspaces } = useSWR(`${serverEndPoint}workspace`, fetcher);
  const { data: workspaceContent } = useSWR(
    `${serverEndPoint}workspace/${parentId}`,
    fetcher
  );
  // const { data: parentWorkspaceContent } = useSWR(
  //   `${serverEndPoint}workspace/${id_parent}`,
  //   fetcher
  // );
  const currentContent = workspaceContent?.find(
    (workspace: any) => workspace.id === contentId
  );
  const {
    data: accessList = [],
    mutate: mutateAccessList,
    isValidating,
  } = useSWR(
    // `${serverEndPoint}content/access/${parentId}`,
    `${serverEndPoint}content/access?workspaceId=${parentId}&contentId=${contentId}&contentType=${currentContent?.contentType}`,
    fetcher,
    {
      revalidateOnFocus: false,
    }
  );

  // let parentWorkspace = workspaces?.find(
  //   (workspace: any) => workspace.id === parentId
  // );
  // if (!parentWorkspace) {
  //   parentWorkspace = parentWorkspaceContent?.find(
  //     (workspace: any) => workspace.id === parentId
  //   );
  // }
  // const parentWorkspaceName = parentWorkspace?.name || "";
  // const ownerName = parentWorkspace?.createdBy || "";
  const { data: modalFetchData = [] } = useSWR(
    `${serverEndPoint}access/user-groups/content?workspaceId=${parentId}&contentId=${contentId}`,
    fetcher
  );

  useEffect(() => {
    const filterUserGroup = () => {
      const lowerCaseQuery = searchQuery.toLowerCase();
      return modalFetchData.filter((user: any) => {
        const userGroupName = user.name?.toLowerCase() || "";
        const email = user.emailId?.toLowerCase() || "";
        return (
          userGroupName?.includes(lowerCaseQuery) ||
          email?.includes(lowerCaseQuery)
        );
      });
    };
    setFilteredModalData(filterUserGroup());
  }, [searchQuery, modalContent, modalFetchData]);

  const handleNewButtonClick = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    // setIsLoading(false);
    if (accessList.length > 0 && originalAccessList.length === 0) {
      setOriginalAccessList(JSON.parse(JSON.stringify(accessList)));
      const permissions: any = {};
      accessList.forEach((access: any) => {
        permissions[access.id] = {};
        permissions[access.id][currentContent.contentType] = access.limit;
      });
      setCustomPermissions(permissions); // Set the customPermissions state based on accessList
    }
    setIsLoading(isValidating);
  }, [accessList]);

  const closeModal = () => {
    setIsModalOpen(false);
    setModalContent("");
    setSearchQuery("");
    setSelectedUsers([]);
  };

  const handleAdd = () => {
    const updatedAccessList = [...accessList];
    selectedUsers.forEach((user: any) => {
      const existingUser = updatedAccessList.find(
        (item) => item.userGroupId === user.id
      );
      if (!existingUser) {
        updatedAccessList.push({
          id: user.id,
          name: user.name,
          role: "custom_creator",
          isGroup: user.group,
          resources: Array(1).fill({ view: false, edit: false, create: false }),
          isBuffered: true,
        });
        setAddedUserIds((prevState: any) => [...prevState, user.id]);
      }
    });
    mutateAccessList(updatedAccessList, false);
    closeModal();
  };
  const handleUserSelection = (userId: any, isGroup: any, userName: any) => {
    setSelectedUsers([{ id: userId, group: isGroup, name: userName }]);
  };

  const showAlert = (message: string, severity: AlertColor) => {
    setTestMessage(message);
    setSeverity(severity);
    setOpenAlert(true);
    setTimeout(() => {
      setOpenAlert(false);
    }, 3000);
  };

  const handleRoleChange = (userGroupId: any, newRole: any) => {
    const updatedAccessList = accessList.map((item: any) =>
      item.id === userGroupId
        ? {
            ...item,
            role: newRole,
            isChange: true,
            customPermissions:
              newRole === "custom_creator"
                ? [
                    { contentTypeId: 2, privilegeId: 1 },
                    { contentTypeId: 3, privilegeId: 1 },
                    { contentTypeId: 4, privilegeId: 1 },
                    { contentTypeId: 5, privilegeId: 1 },
                  ]
                : item.customPermissions, // Set default permissions if role is custom_creator
          }
        : item
    );
    mutateAccessList(updatedAccessList, false);
    setEditedUserIds((prevState: any) => [...prevState, userGroupId]);
    setRevertedRows((prevState: any) => [...prevState, userGroupId]);
  };

  const handlePermissionChange = (
    userGroupId: any,
    contentTypeId: any,
    privilegeId: any
  ) => {
    // Update customPermissions
    setCustomPermissions((prev: any) => ({
      ...prev,
      [userGroupId]: {
        ...prev[userGroupId],
        [contentTypeId]:
          privilegeId !== 0 && privilegeId !== null
            ? Math.min(Math.max(privilegeId, 1), 3)
            : null, // Ensure privilegeId is between 1 and 3, or null
      },
    }));
    if (!editedUserIds.includes(userGroupId)) {
      setEditedUserIds([...editedUserIds, userGroupId]);
    }

    // Mark user as modified for the blue icon (if user role is "custom_creator")
    if (
      customPermissions[userGroupId]?.role === "custom_creator" &&
      !modifiedUsers.includes(userGroupId)
    ) {
      setModifiedUsers([...modifiedUsers, userGroupId]);
    }
    // Create a new accessList and update the respective user's customPermissions
    const updatedAccessList = accessList.map((access: any) => {
      if (access.id === userGroupId) {
        // Create or update the customPermissions for the current user
        const updatedPermissions = [
          {
            contentTypeId: currentContent.contentType,
            privilegeId:
              customPermissions[userGroupId]?.[currentContent.contentType] ||
              null,
          },
        ].filter((permission) => permission.privilegeId !== null);
        return { ...access, customPermissions: updatedPermissions };
      }
      return access;
    });
    mutateAccessList(updatedAccessList, false);
    console.log("Updated accessList:", updatedAccessList);
  };

  const handleOkClick = async (selectedUsers: any) => {
    // const roleMapping: any = {
    //     "owner": 3,
    //     "moderator": 4,
    //     "creator": 5,
    //     "player": 7,
    //     "custom_creator": 6,
    // };

    for (const selectedUser of selectedUsers) {
      console.log("accessList", selectedUser);
      if (
        selectedUser.isBuffered === true ||
        selectedUser.isChange === true ||
        Object.keys(customPermissions).length > 0
      ) {
        //const roleId: any = roleMapping[selectedUser.role || selectedUser.roleName];

        const roleId = roles.find(
          (item: any) =>
            item.value === (selectedUser.role || selectedUser.roleName)
        )?.id;

        let payload = [];

        // Set custom permissions if the role is custom_creator
        if (roleId === 6) {
          payload = [
            {
              granteeId: selectedUser.id,
              isGroup: selectedUser.isGroup,
              roleId: roleId,
              customPermissions: [
                {
                  contentTypeId: currentContent.contentType,
                  privilegeId:
                    customPermissions[selectedUser.id]?.[
                      currentContent.contentType
                    ] || null,
                  contentId: contentId,
                },
              ], //.filter((permission) => permission.privilegeId !== null),
            },
          ];
        } else {
          payload = [
            {
              granteeId: selectedUser.id,
              isGroup: selectedUser.isGroup,
              roleId: roleId,
            },
          ];
        }

        console.log(payload);
        try {
          setIsLoading(true);
          let response;
          const existingUser = accessList.find(
            (item: any) => item.id === selectedUser.id
          );
          const config = {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
              "Content-Type": "application/json",
              // "X-TENANT-ID": "silzila",
            },
          };

          if (existingUser) {
            response = await axios.post(
              `${serverEndPoint}content/user-access?workspaceId=${parentId}`,
              payload,
              config
            );
            mutateAccessList();
            setRevertedRows([]);
            setEditedUserIds([]);
            setSaveMessage(true);
            setModifiedUsers([]);
            setTimeout(() => {
              setSaveMessage(false);
            }, 1000);
          } else {
            console.log("Adding User");
            response = await axios.post(
              `${serverEndPoint}content/user-access?workspaceId=${parentId}`,
              payload,
              config
            );
            console.log("User added:", response.data);
            setAddedUserIds([]);
          }
          showAlert("The changes are saved", "success");
          setAddedUserIds([]);
          mutateAccessList(); // Refresh the access list
        } catch (error) {
          console.error("Error granting access or updating role:", error);
          showAlert("Error changing access. Try Again", "error");
        } finally {
          setIsLoading(false);
        }
      }
    }
  };

  const handleDelete = (
    userGroupId: any,
    userName: any,
    isGroup: any,
    extraParam: any
  ) => {
    setShowUserDeleteConfirmation(true);
    setUser_GroupID(userGroupId);
    setUser_GroupName(userName);
    setIsInGroup(isGroup);
    setUserRoleName(extraParam);
  };

  const cancelDelete = () => {
    setShowUserDeleteConfirmation(false);
  };

  const handleDeleteUser = async () => {
    const token = localStorage.getItem("accessToken");
    const userAdded = addedUserIds.find((user: any) => user === user_GroupId);
    const userOrGroup = accessList.find(
      (item: any) => item.id === user_GroupId
    );
    if (userAdded) {
      //For deleting not saved but Added User
      let updatedAccessList = [...accessList];
      updatedAccessList = updatedAccessList.filter(
        (item) => item.id !== user_GroupId
      );
      mutateAccessList(updatedAccessList, false);
      setAddedUserIds((prevState: any) =>
        prevState.filter((id: any) => id !== user_GroupId)
      );
      showAlert(`${userOrGroup.name} deleted Successfully`, "success");
      setShowUserDeleteConfirmation(false);
    } else {
      try {
        setIsLoading(true);
        const userOrGroup = accessList.find(
          (item: any) => item.id === user_GroupId
        ); // If roleId or roleName are not available in accessList, fetch them dynamically
        let roleId;

        if (userOrGroup && userOrGroup.role) {
          // const roleMapping: any = {
          //     "owner": 3,
          //     "moderator": 4,
          //     "creator": 5,
          //     "player": 7,
          //     "custom_creator": 6
          // };
          roleId = roles.find(
            (item: any) => item.value === userOrGroup.role
          )?.id;
        } else {
          // Default roleId if not available
          roleId = 7; // Assuming "player" as default
        }
        await axios.delete(
          `${serverEndPoint}content/access-deletion?workspaceId=${parentId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              // "X-TENANT-ID": "silzila",
            },
            data: [
              {
                granteeId: user_GroupId,
                isGroup: isInGroup,
                roleId: roleId,
                customPermissions: [
                  {
                    contentId: contentId,
                    contentTypeId: currentContent.contentType,
                  },
                ],
              },
            ],
          }
        );
        let updatedAccessList = [...accessList];
        updatedAccessList = updatedAccessList.filter(
          (item) => item.id !== user_GroupId
        );
        mutateAccessList(updatedAccessList, false);
        console.log("Access deleted");
        showAlert(`${userOrGroup.name} deleted Successfully`, "success");
      } catch (error) {
        console.error("Error deleting access:", error);
        showAlert("Error deleting user. Try Again", "error");
      } finally {
        setIsLoading(false);
        setShowUserDeleteConfirmation(false);
      }
    }
  };
  const removeBufferedUser = (id: any) => {
    const updatedAccessList = accessList.filter((item: any) => item.id !== id);
    mutateAccessList(updatedAccessList, false);
    mutateAccessList();
  };

  const handleRowReload = (userGroupId: any) => {
    const originalState = accessList.find(
      (item: any) => item.id === userGroupId
    );
    if (originalState.isBuffered) {
      removeBufferedUser(originalState.id);
      mutateAccessList();
    } else if (originalState) {
      const updatedAccessList = accessList.map((item: any) =>
        item.id === userGroupId ? originalState : item
      );
      setUsers(
        users.filter(
          (user: any) => !(user.id === userGroupId && user.isBuffered)
        )
      );
      setRevertedRows((prevState: any) =>
        prevState.filter((id: any) => id !== userGroupId)
      );
      setAddedUserIds((prevState: any) =>
        prevState.filter((id: any) => id !== userGroupId)
      );
      setEditedUserIds((prevState: any) =>
        prevState.filter((id: any) => id !== userGroupId)
      );
      mutateAccessList(updatedAccessList, false);
      mutateAccessList();
    }
    mutateAccessList(accessList, false);
    // console.log(`Row with ID ${userGroupId} reloaded`);
  };
  const reloadRow = (userGroupId: any) => {
    setRevertedRows((prevState: any) =>
      prevState.filter((id: any) => id !== userGroupId)
    );
    setAddedUserIds((prevState: any) =>
      prevState.filter((id: any) => id !== userGroupId)
    );
    setEditedUserIds((prevState: any) =>
      prevState.filter((id: any) => id !== userGroupId)
    );
  };
  const reloadButtonFunction = () => {
    setIsReloadButtonClicked(false);
    mutateAccessList();
    const uniqueUserGroupIds = new Set(revertedRows);
    uniqueUserGroupIds.forEach((userGroupId) => reloadRow(userGroupId));
    setIsReverted(false);
    setAddedUserIds([]);
    setEditedUserIds([]);
    showAlert("Action reloaded successfully!", "success");
    if (accessList.length) {
      const permissions: any = {};
      accessList.forEach((access: any) => {
        permissions[access.id] = {};
        permissions[access.id][currentContent.contentType] = access.limit;
      });
      setCustomPermissions(permissions);
    }
  };
  const Functions = () => {
    handleNewButtonClick();
    setModalContent("User");
  };
  const getImageSrc = (contentType: any) => {
    switch (contentType) {
      case 1:
        return "/folder_work.png";
      case 2:
        return "/database_icon.svg";
      case 3:
        return "/ds.svg";
      case 4:
        return "/flat_file_icon.svg";
      case 5:
        return "/playbook_icon.svg";
      default:
        return "/default_icon.svg";
    }
  };
  const renderImage = (content: any) => {
    const imageSrc = getImageSrc(content.contentType);
    return (
      <img
        src={imageSrc}
        alt={content.name}
        style={{
          width: "24px",
          height: "24px",
          objectFit: "contain",
        }}
      />
    );
  };

  const sortedAndFilteredModalData = React.useMemo(() => {
    let sortedItems = [...filteredModalData];
    if (sortField.key) {
      sortedItems.sort((a, b) => {
        let aValue, bValue;
        if (sortField.key === "name") {
          aValue = a.name.toLowerCase();
          bValue = b.name.toLowerCase();
        } else if (sortField.key === "emailId") {
          aValue = a.emailId ? a.emailId.toLowerCase() : "";
          bValue = b.emailId ? b.emailId.toLowerCase() : "";
        }

        if (aValue < bValue) {
          return sortField.direction === "asc" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortField.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortedItems;
  }, [filteredModalData, sortField]);

  const handleSortModal = (key: string) => {
    setSortField((prev) => ({
      key,
      direction: prev.key === key && prev.direction === "asc" ? "desc" : "asc",
    }));
  };

  const getSortIconModal = (key: any) => {
    if (sortField.key === key) {
      return sortField.direction === "asc" ? (
        <img
          className="headerIcon-up-modal"
          src="/sort-up.png"
          alt="Ascending"
        />
      ) : (
        <img
          className="headerIcon-down-modal"
          src="/sort-down.png"
          alt="Descending"
        />
      );
    }
    return <img className="headerIcon-modal" src="/sort.png" alt="Default" />;
  };

  return (
    <div
      className="access-container"
      style={{ height: "100%", padding: "0 auto" }}
    >
      <div style={{ height: "8.6rem", overflowY: "hidden" }}>
        <div className={styles.headerContainer}>
          <button
            className={styles.backLink}
            style={{
              // position: "absolute",
              // left: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              padding: "0",
              fontSize: fontSize.medium,
              color: palette.primary.contrastText,
              gap: "0.5rem",
              fontWeight: "400",
            }}
            onClick={handleBackButton}
          >
            <ArrowBackIcon />
            Back
          </button>
          <div className={styles.header}>
            <h3
              style={{
                fontSize: fontSize.large,
                color: palette.primary.contrastText,
                marginRight: "7rem",
              }}
            >
              Manage Access
            </h3>
          </div>
        </div>
        <div
          className="heading"
        >
          {currentContent ? (
            <>
              {renderImage(currentContent)}
              <h3 style={{ marginLeft: "6px", marginTop: "0" }}>{currentContent?.name}</h3>
            </>
          ) : (
            <img
              src="/folder_work.png"
              style={{ width: "28px", height: "28px" }}
              alt="Folder Icon"
            ></img>
          )}
        </div>
        <div className="subworkspace-action-buttons" style={{ marginTop: "1.45rem", height: "2rem" }}>
          <button
            className={`${styles.createWorkspace} create-workspace`}
            onClick={Functions}
            style={{
              // backgroundColor: palette.primary.main,
              fontSize: fontSize.medium,
              padding: "0.5rem 1rem",
              // borderRadius: "5px",
              // border: "none",
              // marginTop: "1.875rem",
              cursor: "pointer",
            }}
          >
            <img
              className={`${styles.adminAddIconDefault} addIconDefault`}
              src="/add_purple.png"
              alt="Add Users Logo"
              style={{ width: "16px", height: "16px" }}
            />
            <img
              className={`${styles.adminAddIconHover} addIconHover`}
              src="/add_white.png"
              alt="Add Users Logo"
              style={{ width: "16px", height: "16px" }}
            />
            Add
          </button>
        </div>
      </div>
      <div className="access-table_loader_wrapper">
        {isLoading ? (
          <div className="loading-container"
           style={{
              height: "100%",
              width: "100%",
              border: "1px solid white",
              overflowY: "auto",
              overflowX: "hidden",
              marginTop: "0.5rem"
            }}>
            <div className="user-spinner"></div>
          </div>
        ) : accessList.length === 0 ? (
          <div style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              width: "100%",
            }}>
            <div
              className="loading-default-text"
              style={{
                fontSize: fontSize.medium,
                color: palette.primary.contrastText,
              }}
            >
              Currently, no user/group have access to this particular{" "}
              {contentTypes[currentContent?.contentType - 1]?.displayValue}.
              <div>Please add user/groups via Add button.</div>
            </div>
          </div>
        ) : (
          // style for 'save_reload_table_wrapper' is in allPages.css
          <div className="save_reload_table_wrapper">
            <div
              className={`${styles.accesstableContainer} accesstableContainer`}
              style={{
                marginBottom: "0.2rem",
                height: "calc(100% - 4.6rem)",
              }}
            >
              <table className={`${styles.resourcesTable} resourcesTable`}>
                <thead>
                  <tr>
                    <th
                      style={{
                        color: palette.secondary.contrastText,
                        fontSize: fontSize.large,
                        backgroundColor: palette.primary.grey,
                      }}
                      rowSpan={3}
                    >
                      Actions
                    </th>
                    <th
                      style={{
                        color: palette.secondary.contrastText,
                        fontSize: fontSize.large,
                        backgroundColor: palette.primary.grey,
                      }}
                      rowSpan={3}
                    >
                      State
                    </th>
                    <th
                      style={{
                        color: palette.secondary.contrastText,
                        fontSize: fontSize.large,
                        backgroundColor: palette.primary.grey,
                      }}
                      rowSpan={3}
                    >
                      User/Group
                    </th>
                    <th
                      style={{
                        color: palette.secondary.contrastText,
                        fontSize: fontSize.large,
                        backgroundColor: palette.primary.grey,
                      }}
                      rowSpan={3}
                    >
                      {
                        contentTypes[currentContent.contentType - 1]
                          .displayValue
                      }{" "}
                      Role
                    </th>
                    <th
                      style={{
                        color: palette.secondary.contrastText,
                        fontSize: fontSize.large,
                        backgroundColor: palette.primary.grey,
                      }}
                      colSpan={6}
                    >
                      Resources
                    </th>
                  </tr>
                  <tr>
                    <th
                      colSpan={2}
                      style={{
                        color: palette.secondary.contrastText,
                        fontSize: fontSize.large,
                        backgroundColor: palette.primary.grey,
                      }}
                    >
                      {
                        contentTypes[currentContent.contentType - 1]
                          .displayValue
                      }
                    </th>
                  </tr>
                  <tr>
                    <React.Fragment key={currentContent.contentType - 1}>
                      {/* <Tooltip title="None" arrow>
                        <th
                          style={{
                            color: palette.secondary.contrastText,
                            fontSize: fontSize.large,
                            backgroundColor: palette.primary.grey,
                          }}
                        >
                          <img
                            src="/none_header.png"
                            alt="None"
                            style={{ width: "17px", height: "17px" }}
                          ></img>
                        </th>
                      </Tooltip> */}
                      <Tooltip title="View" arrow>
                        <th
                          style={{
                            color: palette.secondary.contrastText,
                            fontSize: fontSize.large,
                            backgroundColor: palette.primary.grey,
                          }}
                        >
                          <img
                            src="/view_header.png"
                            alt="View"
                            style={{ width: "17px", height: "17px" }}
                          ></img>
                        </th>
                      </Tooltip>
                      <Tooltip title="Edit" arrow>
                        <th
                          style={{
                            color: palette.secondary.contrastText,
                            fontSize: fontSize.large,
                            backgroundColor: palette.primary.grey,
                          }}
                        >
                          <img
                            src="/edit_header.png"
                            alt="Edit"
                            style={{ width: "17px", height: "17px" }}
                          ></img>
                        </th>
                      </Tooltip>
                      {/* <Tooltip title="Create" arrow>
                        <th
                          style={{
                            color: palette.secondary.contrastText,
                            fontSize: fontSize.large,
                            backgroundColor: palette.primary.contrastText,
                          }}
                        >
                          <img
                            src="/create_header.png"
                            alt="Create"
                            style={{ width: "17px", height: "17px" }}
                          ></img>
                        </th>
                      </Tooltip> */}
                    </React.Fragment>
                  </tr>
                </thead>
                <tbody>
                  {accessList.map((access: any) => (
                    <tr key={access.id}>
                      <td className={styles.actButton}>
                        {/* Row reload disabled for now */}
                        {/* <button
                        className={styles.iconButton}
                        onClick={() => handleRowReload(access.id)}
                      >
                        <Tooltip title="Reload">
                          <img
                            src={renderImage(access.id)}
                            alt="Reload Row"
                            style={{ width: "17px", height: "17px" }}
                          />
                        </Tooltip>
                      </button> */}
                        <button
                          className={styles.iconButton}
                          onClick={() =>
                            handleDelete(
                              access.id,
                              access.name,
                              access.isGroup,
                              access.roleName
                            )
                          }
                        >
                          <Tooltip title="Delete">
                            <img
                              src="/delete_red.png"
                              alt="Delete User"
                              style={{ width: "17px", height: "17px" }}
                            ></img>
                          </Tooltip>
                        </button>
                      </td>
                      <td className={styles.stateButton}>
                        {/* Render nothing if the user has no added/edited state */}
                        {!addedUserIds.includes(access.id) &&
                          !editedUserIds.includes(access.id) &&
                          !modifiedUsers.includes(access.id) && <span></span>}

                        {/* Show 'add' icon if the user was added */}
                        {addedUserIds.includes(access.id) && (
                          // !editedUserIds.includes(access.id) &&
                          <Tooltip title="Add User">
                            <img
                              src="/add_access_active.png"
                              alt="Add Users Logo"
                              width={17}
                              height={17}
                            />
                          </Tooltip>
                        )}

                        {/* Show 'edit' icon if the user was edited */}
                        {editedUserIds.includes(access.id) &&
                          !addedUserIds.includes(access.id) && (
                            <Tooltip title="Edit User">
                              <img
                                src="/edit_access.png"
                                alt="Edit Users Logo"
                                width={17}
                                height={17}
                              />
                            </Tooltip>
                          )}
                        {modifiedUsers.includes(access.id) && (
                          <Tooltip title="Edit User">
                            <img
                              src="/edit_access.png" // Path to the blue icon
                              alt="Modified Icon"
                              width={17}
                              height={17}
                            />
                          </Tooltip>
                        )}
                      </td>

                      <td
                        style={{
                          fontSize: fontSize.medium,
                          color: palette.primary.contrastText,
                          textAlign: "left",
                        }}
                      >
                        {access.name}
                      </td>
                      <td
                        style={{
                          fontSize: fontSize.medium,
                          color: palette.primary.contrastText,
                          textAlign: "left",
                        }}
                      >
                        {/* <select
                        className={styles.roleDropdown}
                        onChange={(e) =>
                          handleRoleChange(access.id, e.target.value)
                        }
                        value={access.role}
                      >
                        {roles.map((role, index) => (
                          <option key={index} value={role.value}>
                            {role.displayValue}
                          </option>
                        ))}
                      </select> */}
                        Custom Creator
                      </td>
                      {Array(1)
                        .fill(null)
                        .map((_, index) => {
                          const privilegeId =
                            customPermissions[access.id]?.[
                              currentContent.contentType
                            ] || 0; // Default to 0 if no permission is set

                          return (
                            <React.Fragment key={access.id}>
                              <td colSpan={2}>
                                <StepSlider
                                  role={access.role}
                                  column={currentContent.contentType} // Start column IDs from 1
                                  initialValue={privilegeId} // Pass initial privilegeId to StepSlider
                                  hideCreate={true}
                                  onPermissionChange={(
                                    column: any,
                                    privilegeId: any
                                  ) =>
                                    handlePermissionChange(
                                      access.id,
                                      column,
                                      privilegeId
                                    )
                                  }
                                />
                              </td>
                            </React.Fragment>
                          );
                        })}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {/*If you change the height of below div make sure to change the height of the div having class name 'accesstable-container' as well  */}
            <div className={styles.tableButtons} style={{ height: "2rem" }}>
              <div>
                <button
                  className={styles.reloadButton}
                  onClick={() => setIsReloadButtonClicked(true)}
                  style={{
                    color: palette.primary.contrastText,
                    fontSize: fontSize.large,
                  }}
                >
                  Reload
                </button>
                <button
                  className={styles.okButton}
                  onClick={() => handleOkClick(accessList)}
                  style={{
                    color: palette.secondary.contrastText,
                    fontSize: fontSize.large,
                    backgroundColor: palette.secondary.light,
                  }}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
        <AcceptRejectDialog
          open={isReloadButtonClicked}
          closeFunction={() => setIsReloadButtonClicked(false)}
          acceptFunction={reloadButtonFunction}
          rejectFunction={() => setIsReloadButtonClicked(false)}
          heading="Are You Sure?"
          messages={[
            {
              text: "All the unsaved changes will be ignored. Do you still wto reload the page?",
            },
          ]}
          acceptText="Yes"
          rejectText="No"
          varient="animated"
          rejectButtonStyle={{
            border: "2px solid gray",
            color: "gray",
          }}
          acceptButtonStyle={{
            border: "2px solid #2bb9bb",
            color: "#2bb9bb",
          }}
          acceptTransition={{
            transitionTime: "0.2s",
            backgroundColor: "secondary.contrastText",
            color: "primary.main",
            hoverBackgroundColor: "primary.main",
            hoverColor: "secondary.contrastText",
          }}
          rejectTransition={{
            transitionTime: "0.2s",
            backgroundColor: "secondary.contrastText",
            color: "gray",
            hoverBackgroundColor: "gray",
            hoverColor: "secondary.contrastText",
          }}
        />
        {showUserDeleteConfirmation ? (
          <Dialog open={showUserDeleteConfirmation} onClose={cancelDelete}>
        <div className="access-remove-modal">
          <div className="access-remove-modalContent-close">
          <Tooltip title="Close">
              <CloseIcon
                onClick={cancelDelete}
                sx={{
                  fontSize: "large",
                  color: "#545454",
                  cursor: "pointer",
                  "&:hover": {
                    color: "red",
                  },
                }}
              />
            </Tooltip>
          </div>

          <div className="access-remove-modalContent-p">
              <p>
                Are you sure you want to delete the{" "}
                {!isInGroup ? "user" : "group"} {user_GroupName}?
              </p>
          </div>
              <div className="access-remove-modalContent-Buttons">
                <button
                  onClick={cancelDelete}
                  className="access-remove-modalCancel"
                >
                  Cancel
                </button>
                <button
                  onClick={handleDeleteUser}
                  className="access-remove-modalConfirm"
                >
                  Confirm
                </button>
              </div>
            </div>
            </Dialog>
        ) : null}

        <Dialog open={isModalOpen} onClose={closeModal} maxWidth="md">
          <div className="accessListModal">
            <div className="accessListModalContent">
              <div className="accessListHeaderSection">
                <div className="accessListHeaderClose">
                  <Tooltip title="Close">
                    <CloseIcon
                      onClick={closeModal}
                      sx={{
                        fontSize: "large",
                        color: "#545454",
                        cursor: "pointer",
                        "&:hover": {
                          color: "red",
                        },
                      }}
                    />
                  </Tooltip>
                </div>

                <h3 className="access-modal-title">
                  {modalContent === "User" ? "Select User" : "Select Group"}
                </h3>

                <div className={styles.adminListModalTabs}>
                  <div className={styles.tabContainer}>
                    <div
                      onClick={() => setModalContent("User")}
                      className={`${styles.tab} ${
                        modalContent === "User" ? styles.activeTab : ""
                      }`}
                    >
                      Users
                    </div>

                    <div
                      onClick={() => setModalContent("Groups")}
                      className={`${styles.tab} ${
                        modalContent === "Groups" ? styles.activeTab : ""
                      }`}
                    >
                      Groups
                    </div>
                  </div>
                </div>

                {modalContent === "User" && (
                  <div className="accessListSearchContainer">
                    {/* User Search */}
                    <input
                      type="text"
                      placeholder="Search users"
                      className="accessListSearchInput"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <img
                      src="/glass.png"
                      alt="Search Icon"
                      className="accessListSearchIcon"
                    />
                  </div>
                )}
                {modalContent === "Groups" && (
                  <div className="adminListSearchContainer">
                    {/* Group Search */}
                    <input
                      type="text"
                      placeholder="Search groups"
                      className="accessListSearchInput"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <img
                      src="/glass.png"
                      alt="Search Icon"
                      className="accessListSearchIcon"
                    />
                  </div>
                )}
              </div>

              <div className="accessListTableContainer">
                {modalContent === "User" && (
                  <table
                    className={`${styles.accessListUserList} accessListUserList`}
                  >
                    <thead>
                      <tr>
                        <th></th>
                        <th></th>
                        <th onClick={() => handleSortModal("name")}>
                          Name{" "}
                          <span className="icon-wrapper-modal">
                            {getSortIconModal("name")}
                          </span>
                        </th>
                        <th onClick={() => handleSortModal("emailId")}>
                          Email{" "}
                          <span className="icon-wrapper-modal">
                            {getSortIconModal("emailId")}
                          </span>
                        </th>
                      </tr>
                    </thead>

                    {sortedAndFilteredModalData.length === 0 &&
                    searchQuery.length !== 0 ? (
                      <div className="modal-content-no-user-info">
                        <p
                          className="loading-default-text"
                          style={{
                            fontSize: fontSize.medium,
                            color: palette.primary.contrastText,
                          }}
                        >
                          No user found.
                        </p>
                      </div>
                    ) : (
                      <tbody>
                        {sortedAndFilteredModalData
                          .filter(
                            (user: any) =>
                              !user.isGroup && !addedUserIds.includes(user.id)
                          )
                          .map((user: any) => (
                            <tr key={user.id} className={styles.accessUserItem}>
                              <td>
                                <input
                                  type="radio"
                                  className={styles.radioButton}
                                  checked={selectedUsers.some(
                                    (selectedUser: any) =>
                                      selectedUser.id === user.id
                                  )}
                                  onChange={() =>
                                    handleUserSelection(
                                      user.id,
                                      user.isGroup,
                                      user.name
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <img
                                  src={
                                    user.profileImage
                                      ? `data:image/jpeg;base64,${user.profileImage}`
                                      : "/default.png"
                                  }
                                  alt="Users Icon"
                                  height={17}
                                  width={17}
                                />
                              </td>
                              <td>{user.name}</td>
                              <td>{user.emailId}</td>
                            </tr>
                          ))}
                      </tbody>
                    )}
                  </table>
                )}

                {modalContent === "Groups" && (
                  <table
                    className={`${styles.accessListGroupList} accessListGroupList`}
                  >
                    <thead>
                      <tr>
                        <th></th>
                        <th></th>
                        <th onClick={() => handleSortModal("name")}>
                          Name{" "}
                          <span className="icon-wrapper-modal">
                            {getSortIconModal("name")}
                          </span>
                        </th>
                      </tr>
                    </thead>

                    {sortedAndFilteredModalData.length === 0 &&
                    searchQuery.length !== 0 ? (
                      <div className="modal-content-no-user-info">
                        <p
                          className="loading-default-text"
                          style={{
                            fontSize: fontSize.medium,
                            color: palette.primary.contrastText,
                          }}
                        >
                          No group found
                        </p>
                      </div>
                    ) : (
                      <tbody>
                        {sortedAndFilteredModalData
                          .filter(
                            (group: any) =>
                              group.isGroup && !addedUserIds.includes(group.id)
                          )
                          .map((group: any) => (
                            <tr
                              key={group.id}
                              className={styles.accessUserItem}
                            >
                              <td>
                                <input
                                  type="radio"
                                  className={styles.radioButton}
                                  checked={selectedUsers.some(
                                    (selectedUser: any) =>
                                      selectedUser.id === group.id
                                  )}
                                  onChange={() =>
                                    handleUserSelection(
                                      group.id,
                                      group.isGroup,
                                      group.name
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <img
                                  src="/groups.png"
                                  alt="Group Icon"
                                  style={{ width: "17px", height: "17px" }}
                                />
                              </td>
                              <td>{group.name}</td>
                            </tr>
                          ))}
                      </tbody>
                    )}
                  </table>
                )}
              </div>

              <div className="accessListFooterSection">
                <HoverButton
                onClick={handleAdd}
                text="Add"
                color="secondary.light"
                hoverColor="secondary.contrastText"
                backgroundColor="secondary.contastText"
                hoverBackgroundColor="secondary.light"
                sx={{
                  ...ffButtonStyle,
                  border: `1px solid ${palette.secondary.light}`,
                  fontSize: fontSize.medium,
                  lineHeight: "normal",
                  borderRadius: "5px",
                  padding: "10px 20px",
                  margin: "0"
                }}
                transitionTime="0.2s"
              />
              </div>
            </div>
          </div>
        </Dialog>

        <NotificationDialog
          openAlert={openAlert}
          severity={severity}
          testMessage={testMessage}
          onCloseAlert={() => {
            setOpenAlert(false);
            setTestMessage("");
          }}
        />
    </div>
  );
};
export default AccessforContents;