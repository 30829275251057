import React, { useState, useEffect } from "react";
import styles from "./access.module.css";
import { useParams } from "react-router-dom";
import { AlertColor, Tooltip } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import axios from "axios";
import useSWR from "swr";
import StepSlider from "./Slider.jsx";
import { Dialog } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation } from "react-router-dom";
import FetchData from "./ServerCall/FetchData";
import {
  NotificationDialog,
  AcceptRejectDialog,
} from "./CommonFunctions/DialogComponents";
import { serverEndPoint } from "./ServerCall/EnvironmentVariables";
import "./allPages.css";
import { FormControl, Select, MenuItem } from "@mui/material";
import { fontSize, palette } from "..";
import { ffDialogTitle, ffButtonStyle } from "../Components/Controls/muiStyles";
import HoverButton from "./Buttons/HoverButton";


const Access = () => {
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [testMessage, setTestMessage] = useState<string>("");
  const [severity, setSeverity] = useState<AlertColor>("success");
  const [isModalOpen, setIsModalOpen] = useState<any>(false);
  const [modalContent, setModalContent] = useState<any>("User");
  const [searchQuery, setSearchQuery] = useState<any>("");
  // const [modalData, setModalData] = useState<any[]>([]);
  const [filteredModalData, setFilteredModalData] = useState<any[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<any>([]);
  const [addedUserIds, setAddedUserIds] = useState<any>([]);
  const [editedUserIds, setEditedUserIds] = useState<any>([]);
  const { parentId } = useParams();
  const [originalAccessList, setOriginalAccessList] = useState<any>([]);
  const [revertedRows, setRevertedRows] = useState<any>([]);
  const [users, setUsers] = useState<any>([]);
  const [isReverted, setIsReverted] = useState<any>(false);
  const [customPermissions, setCustomPermissions] = useState<any>({});
  const [saveMessage, setSaveMessage] = useState(false);
  const [modifiedUsers, setModifiedUsers] = useState<any>([]);
  const [showUserDeleteConfirmation, setShowUserDeleteConfirmation] =
    useState(false);
  const [user_GroupId, setUser_GroupID] = useState<any>();
  const [user_GroupName, setUser_GroupName] = useState<any>();
  const [userRoleName, setUserRoleName] = useState<any>();
  const [isInGroup, setIsInGroup] = useState(false);
  // const [isLoading, setIsLoading] = useState<any>(true);

  // const [workspaces, setWorkspaces] = useState<any[]>([]);
  // const [accessList, setAccessList] = useState<any[]>([]);
  // const [workspaceContent, setWorkspaceContent] = useState<any>(null);
  // const [parentWorkspaceContent, setParentWorkspaceContent] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [modalData, setModalData] = useState<any[]>([]);
  const [isReloadButtonClicked, setIsReloadButtonClicked] = useState(false);
  const [sortField, setSortField] = useState<{
    key: string;
    direction: "asc" | "desc";
  }>({ key: "name", direction: "asc" });

  const [disableOwnerAccess, setDisableOwnerAccess] = useState<boolean>(false);
  const [disableModeratorAccess, setDisableModeratorAccess] =
    useState<boolean>(false);

  const id_parent = localStorage.getItem("parentWorkspaceId");

  const navigate = useNavigate();
  const location = useLocation();

  const handleBackButton = () => {
    navigate(-1);
  };

  let roles: any[] = [
    { id: 3, value: "owner", displayValue: "Owner" },
    { id: 4, value: "moderator", displayValue: "Moderator" },
    { id: 5, value: "creator", displayValue: "Creator" },
    { id: 6, value: "custom_creator", displayValue: "Custom Creator" },
    { id: 7, value: "player", displayValue: "Player" },
  ];

  // const fetcher = (url: string) =>
  //   axios
  //     .get(url, {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  //         "X-TENANT-ID": "silzila",
  //       },
  //     })
  //     .then((res) => res.data);

  // const { data: workspaces } = useSWR(
  //   "https://dev.silzila.com/api/workspace",
  //   fetcher
  // );
  // const { data: accessList = [], mutate: mutateAccessList } = useSWR(
  //   `https://dev.silzila.com/api/workspace/access/${parentId}`,
  //   fetcher
  // );
  // const { data: workspaceContent } = useSWR(
  //   `https://dev.silzila.com/api/workspace/${parentId}`,
  //   fetcher
  // );
  // const { data: parentWorkspaceContent } = useSWR(
  //   `https://dev.silzila.com/api/workspace/${id_parent}`,
  //   fetcher
  // );

  const fetcher = (url: string) =>
    axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          // "X-TENANT-ID": "silzila",
        },
      })
      .then((res) => res.data);

  const { data: workspaces } = useSWR(`${serverEndPoint}workspace`, fetcher);

  const {
    data: accessList = [],
    mutate: mutateAccessList,
    isValidating,
  } = useSWR(`${serverEndPoint}workspace/access/${parentId}`, fetcher, {
    revalidateOnFocus: false,
  });
  const { data: workspaceContent } = useSWR(
    `${serverEndPoint}workspace/${parentId}`,
    fetcher
  );
  // const { data: parentWorkspaceContent } = useSWR(
  //   `${serverEndPoint}workspace/${id_parent}`,
  //   fetcher
  // );

  // const fetchData = async () => {
  //   setIsLoading(true);
  //   try {
  //     // Fetch workspaces
  //     const workspaceResponse = await FetchData({
  //       requestType: "noData",
  //       method: "GET",
  //       url: "workspace",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  //         "X-TENANT-ID": "silzila",
  //       },
  //     });
  //     if (workspaceResponse.status) {
  //       setWorkspaces(workspaceResponse.data);
  //     } else {
  //       throw new Error(workspaceResponse.data.detail || "Failed to fetch workspaces");
  //     }

  //     // Fetch access list
  //     const accessListResponse = await FetchData({
  //       requestType: "noData",
  //       method: "GET",
  //       url: `workspace/access/${parentId}`,
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  //         "X-TENANT-ID": "silzila",
  //       },
  //     });
  //     if (accessListResponse.status) {
  //       setAccessList(accessListResponse.data);
  //     } else {
  //       throw new Error(accessListResponse.data.detail || "Failed to fetch access list");
  //     }

  //     // Fetch workspace content
  //     const workspaceContentResponse = await FetchData({
  //       requestType: "noData",
  //       method: "GET",
  //       url: `workspace/${parentId}`,
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  //         "X-TENANT-ID": "silzila",
  //       },
  //     });
  //     if (workspaceContentResponse.status) {
  //       setWorkspaceContent(workspaceContentResponse.data);
  //     } else {
  //       throw new Error(workspaceContentResponse.data.detail || "Failed to fetch workspace content");
  //     }

  //     // Fetch parent workspace content
  //     const parentWorkspaceContentResponse = await FetchData({
  //       requestType: "noData",
  //       method: "GET",
  //       url: `workspace/${id_parent}`,
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  //         "X-TENANT-ID": "silzila",
  //       },
  //     });
  //     if (parentWorkspaceContentResponse.status) {
  //       setParentWorkspaceContent(parentWorkspaceContentResponse.data);
  //     } else {
  //       throw new Error(parentWorkspaceContentResponse.data.detail || "Failed to fetch parent workspace content");
  //     }
  //   } catch (err: any) {
  //     console.error("Error fetching data:", err.message);
  //     setError(err.message);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   fetchData();
  // }, [parentId, id_parent]);

  let parentWorkspace = workspaces?.find(
    (workspace: any) => workspace.id === parentId
  );
  // if (!parentWorkspace) {
  //   parentWorkspace = parentWorkspaceContent?.find(
  //     (workspace: any) => workspace.id === parentId
  //   );
  // }
  const parentWorkspaceName =
    parentWorkspace?.name || location.state?.workspaceName || "";
  const ownerName = parentWorkspace?.createdBy || "";
  const { data: modalFetchData = [] } = useSWR(
    `${serverEndPoint}access/user-groups/workspace?workspaceId=${parentId}`,
    fetcher
  );

  useEffect(() => {
    const filterUserGroup = () => {
      const lowerCaseQuery = searchQuery.toLowerCase();
      return modalFetchData.filter((user: any) => {
        const userGroupName = user.name?.toLowerCase() || "";
        const email = user.emailId?.toLowerCase() || "";
        return (
          userGroupName?.includes(lowerCaseQuery) ||
          email?.includes(lowerCaseQuery)
        );
      });
    };
    setFilteredModalData(filterUserGroup());
  }, [searchQuery, modalContent, modalFetchData]);

  const disableAccessOptions = (workSpace: any) => {
    if (workSpace.roleId === 3) {
      setDisableOwnerAccess(true);
    }
    if (workSpace.roleId === 4) {
      setDisableOwnerAccess(true);
      setDisableModeratorAccess(true);
    }
  };

  useEffect(() => {
    let workSpaces: any = sessionStorage.getItem("workspaces");

    workSpaces = workSpaces ? JSON.parse(workSpaces) : [];

    let currentWorkspace = workSpaces.find((item: any) => item.id === parentId);

    if (!currentWorkspace) {
      workSpaces = sessionStorage.getItem("subWorkspaces");

      workSpaces = workSpaces ? JSON.parse(workSpaces) : [];

      currentWorkspace = workSpaces.find((item: any) => item.id === parentId);
    }

    disableAccessOptions(currentWorkspace);
  }, [parentId]);

  const handleNewButtonClick = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    // setIsLoading(false);
    if (accessList.length > 0 && originalAccessList.length === 0) {
      setOriginalAccessList(JSON.parse(JSON.stringify(accessList)));
      const permissions: any = {};
      accessList.forEach((access: any) => {
        if (access.customPermissions && access.customPermissions.length > 0) {
          permissions[access.id] = {};
          access.customPermissions.forEach((permission: any) => {
            permissions[access.id][permission.contentTypeId] =
              permission.privilegeId;
          });
        }
      });
      setCustomPermissions(permissions); // Set the customPermissions state based on accessList
    }
    setIsLoading(isValidating);
  }, [accessList]);

  const closeModal = () => {
    setIsModalOpen(false);
    setModalContent("");
    setSearchQuery("");
    setSelectedUsers([]);
  };

  const isAccessRoleDisabled = (id: number) => {
    if (id === 3) {
      return disableOwnerAccess;
    } else if (id == 4) {
      return disableModeratorAccess;
    } else {
      return false;
    }
  };

  const handleAdd = () => {
    const updatedAccessList = [...accessList];
    selectedUsers.forEach((user: any) => {
      const existingUser = updatedAccessList.find(
        (item) => item.userGroupId === user.id
      );
      if (!existingUser) {
        updatedAccessList.push({
          id: user.id,
          name: user.name,
          role: "player",
          isGroup: user.group,
          resources: Array(5).fill({ view: false, edit: false, create: false }),
          isBuffered: true,
        });
        setAddedUserIds((prevState: any) => [...prevState, user.id]);
      }
    });
    mutateAccessList(updatedAccessList, false);
    // fetchData();
    // setAccessList(updatedAccessList);
    closeModal();
  };
  const handleUserSelection = (userId: any, isGroup: any, userName: any) => {
    setSelectedUsers([{ id: userId, group: isGroup, name: userName }]);
  };

  const showAlert = (message: string, severity: AlertColor) => {
    setTestMessage(message);
    setSeverity(severity);
    setOpenAlert(true);
    setTimeout(() => {
      setOpenAlert(false);
    }, 3000);
  };

  const handleRoleChange = (userGroupId: any, newRole: any) => {
    const updatedAccessList = accessList.map((item: any) =>
      item.id === userGroupId
        ? {
            ...item,
            role: newRole,
            isChange: true,
            customPermissions:
              newRole === "custom_creator"
                ? [
                    { contentTypeId: 2, privilegeId: 1 },
                    { contentTypeId: 3, privilegeId: 1 },
                    { contentTypeId: 4, privilegeId: 1 },
                    { contentTypeId: 5, privilegeId: 1 },
                  ]
                : item.customPermissions, // Set default permissions if role is custom_creator
          }
        : item
    );
    mutateAccessList(updatedAccessList, false);
    // setAccessList(updatedAccessList);
    setEditedUserIds((prevState: any) => [...prevState, userGroupId]);
    setRevertedRows((prevState: any) => [...prevState, userGroupId]);
  };

  const handlePermissionChange = (
    userGroupId: any,
    contentTypeId: any,
    privilegeId: any
  ) => {
    // Update customPermissions
    setCustomPermissions((prev: any) => ({
      ...prev,
      [userGroupId]: {
        ...prev[userGroupId],
        [contentTypeId]:
          privilegeId !== 0 && privilegeId !== null
            ? Math.min(Math.max(privilegeId, 1), 3)
            : null, // Ensure privilegeId is between 1 and 3, or null
      },
    }));
    if (!editedUserIds.includes(userGroupId)) {
      setEditedUserIds([...editedUserIds, userGroupId]);
    }

    // Mark user as modified for the blue icon (if user role is "custom_creator")
    if (
      customPermissions[userGroupId]?.role === "custom_creator" &&
      !modifiedUsers.includes(userGroupId)
    ) {
      setModifiedUsers([...modifiedUsers, userGroupId]);
    }
    // Create a new accessList and update the respective user's customPermissions
    const updatedAccessList = accessList.map((access: any) => {
      if (access.id === userGroupId) {
        // Create or update the customPermissions for the current user
        const updatedPermissions = [
          {
            contentTypeId: 2,
            privilegeId: customPermissions[userGroupId]?.[2] || null,
          },
          {
            contentTypeId: 3,
            privilegeId: customPermissions[userGroupId]?.[3] || null,
          },
          {
            contentTypeId: 4,
            privilegeId: customPermissions[userGroupId]?.[4] || null,
          },
          {
            contentTypeId: 5,
            privilegeId: customPermissions[userGroupId]?.[5] || 3, // Default to 3 for Playbook as none not allowed for Playbook
          },
        ].filter((permission) => permission.privilegeId !== null);
        return { ...access, customPermissions: updatedPermissions };
      }
      return access;
    });
    mutateAccessList(updatedAccessList, false);
    // setAccessList(updatedAccessList);
    console.log("Updated accessList:", updatedAccessList);
  };

  const handleOkClick = async (selectedUsers: any) => {
    // const roleMapping: any = {
    //     "owner": 3,
    //     "moderator": 4,
    //     "creator": 5,
    //     "player": 7,
    //     "custom_creator": 6,
    // };

    for (const selectedUser of selectedUsers) {
      console.log("accessList", selectedUser);
      if (
        selectedUser.isBuffered === true ||
        selectedUser.isChange === true ||
        Object.keys(customPermissions).length > 0
      ) {
        //const roleId: any = roleMapping[selectedUser.role || selectedUser.roleName];

        const roleId = roles.find(
          (item: any) =>
            item.value === (selectedUser.role || selectedUser.roleName)
        )?.id;

        let payload = [];

        // Set custom permissions if the role is custom_creator
        if (roleId === 6) {
          payload = [
            {
              granteeId: selectedUser.id,
              isGroup: selectedUser.isGroup,
              roleId: roleId,
              customPermissions: [
                {
                  contentTypeId: 2,
                  privilegeId: customPermissions[selectedUser.id]?.[2] || null,
                },
                {
                  contentTypeId: 3,
                  privilegeId: customPermissions[selectedUser.id]?.[3] || null,
                },
                {
                  contentTypeId: 4,
                  privilegeId: customPermissions[selectedUser.id]?.[4] || null,
                },
                {
                  contentTypeId: 5,
                  privilegeId: customPermissions[selectedUser.id]?.[5] || 3, // Default to 3 for Playbook as none not allowed for Playbook
                },
              ].filter((permission) => permission.privilegeId !== null),
            },
          ];
        } else {
          payload = [
            {
              granteeId: selectedUser.id,
              isGroup: selectedUser.isGroup,
              roleId: roleId,
            },
          ];
        }

        console.log(payload);
        try {
          setIsLoading(true);
          let response;
          const existingUser = accessList.find(
            (item: any) => item.id === selectedUser.id
          );
          const config = {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
              "Content-Type": "application/json",
              // "X-TENANT-ID": "silzila",
            },
          };

          // if (existingUser) {
          //   response = await axios.post(
          //     https://dev.silzila.com/api/workspace/user-access?workspaceId=${parentId},
          //     payload,
          //     config
          //   );
          // else {
          //   console.log("Adding User");
          //   response = await axios.post(
          //     `https://dev.silzila.com/api/workspace/user-access?workspaceId=${parentId}`,
          //     payload,
          //     config
          //   );
          //   console.log("User added:", response.data);
          //   setAddedUserIds([]);
          // }
          // setAddedUserIds([]);
          // // mutateAccessList(); // Refresh the access list
          // fetchData();
          if (existingUser) {
            const fetchResponse = await FetchData({
              requestType: "withData",
              method: "POST",
              url: `workspace/user-access?workspaceId=${parentId}`,
              headers: config.headers,
              data: payload,
            });

            if (fetchResponse.status) {
              mutateAccessList();
              // fetchData();
              setRevertedRows([]);
              setEditedUserIds([]);
              setSaveMessage(true);
              setModifiedUsers([]);
              setTimeout(() => {
                setSaveMessage(false);
              }, 1000);
            } else {
              console.error(
                "Error granting access or updating role:",
                fetchResponse.data
              );
            }
          } else {
            console.log("Adding User");

            const fetchResponse = await FetchData({
              requestType: "withData",
              method: "POST",
              url: `workspace/user-access?workspaceId=${parentId}`,
              headers: config.headers,
              data: payload,
            });

            if (fetchResponse.status) {
              console.log("User added:", fetchResponse.data);
              setAddedUserIds([]);
              mutateAccessList();
              // fetchData();  // Refresh the data
            } else {
              console.error("Error adding user:", fetchResponse.data);
            }
          }
          showAlert("The changes are saved", "success");
          setAddedUserIds([]);
          mutateAccessList();
          // fetchData(); // Refresh the access list
        } catch (error) {
          console.error("Error granting access or updating role:", error);
          showAlert("Error changing access. Try Again", "error");
        } finally {
          setIsLoading(false);
        }
      }
    }
  };

  const handleDelete = (
    userGroupId: any,
    userName: any,
    isGroup: any,
    extraParam: any
  ) => {
    setShowUserDeleteConfirmation(true);
    setUser_GroupID(userGroupId);
    setUser_GroupName(userName);
    setIsInGroup(isGroup);
    setUserRoleName(extraParam);
  };

  const cancelDelete = () => {
    setShowUserDeleteConfirmation(false);
  };

  const handleDeleteUser = async () => {
    const token = localStorage.getItem("accessToken");
    const userAdded = addedUserIds.find((user: any) => user === user_GroupId);
    const userOrGroup = accessList.find(
      (item: any) => item.id === user_GroupId
    );
    if (userAdded) {
      //For deleting not saved but Added User
      let updatedAccessList = [...accessList];
      updatedAccessList = updatedAccessList.filter(
        (item) => item.id !== user_GroupId
      );
      mutateAccessList(updatedAccessList, false);
      showAlert(`${userOrGroup.name} deleted Successfully`, "success");
      setAddedUserIds((prevState: any) =>
        prevState.filter((id: any) => id !== user_GroupId)
      );
      setShowUserDeleteConfirmation(false);
    } else {
      try {
        setIsLoading(true);
        const userOrGroup = accessList.find(
          (item: any) => item.id === user_GroupId
        ); // If roleId or roleName are not available in accessList, fetch them dynamically
        let roleId;

        if (userOrGroup && userOrGroup.role) {
          // const roleMapping: any = {
          //     "owner": 3,
          //     "moderator": 4,
          //     "creator": 5,
          //     "player": 7,
          //     "custom_creator": 6
          // };
          roleId = roles.find(
            (item: any) => item.value === userOrGroup.role
          )?.id;
        } else {
          // Default roleId if not available
          roleId = 7; // Assuming "player" as default
        }
        // await axios.delete(
        //   `https://dev.silzila.com/api/workspace/access-deletion?workspaceId=${parentId}`,
        //   {
        //     headers: {
        //       Authorization: `Bearer ${token}`,
        //       "Content-Type": "application/json",
        //       "X-TENANT-ID": "silzila",
        //     },
        //     data: [
        //       {
        //         granteeId: userGroupId,
        //         isGroup: isGroup,
        //         roleId: roleId,
        //       },
        //     ],
        //   }
        // );
        await FetchData({
          requestType: "withData",
          method: "DELETE", // Using DELETE method
          url: `workspace/access-deletion?workspaceId=${parentId}`,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            // "X-TENANT-ID": "silzila",
          },
          data: [
            {
              granteeId: user_GroupId,
              isGroup: isInGroup,
              roleId: roleId,
            },
          ],
        });
        let updatedAccessList = [...accessList];
        updatedAccessList = updatedAccessList.filter(
          (item) => item.id != user_GroupId
        );
        mutateAccessList();
        // fetchData();

        console.log("Access deleted");
        showAlert(`${userOrGroup.name} deleted Successfully`, "success");
      } catch (error) {
        console.error("Error deleting access:", error);
        showAlert("Error deleting user. Try Again", "error");
      } finally {
        setIsLoading(false);
        setShowUserDeleteConfirmation(false);
      }
    }
  };
  const removeBufferedUser = (id: any) => {
    const updatedAccessList = accessList.filter((item: any) => item.id !== id);
    mutateAccessList(updatedAccessList, false);
    mutateAccessList();
    // setAccessList(updatedAccessList);
    // fetchData();
  };

  const handleRowReload = (userGroupId: any) => {
    const originalState = accessList.find(
      (item: any) => item.id === userGroupId
    );
    if (originalState.isBuffered) {
      removeBufferedUser(originalState.id);
      mutateAccessList();
      // fetchData();
    } else if (originalState) {
      const updatedAccessList = accessList.map((item: any) =>
        item.id === userGroupId ? originalState : item
      );
      setUsers(
        users.filter(
          (user: any) => !(user.id === userGroupId && user.isBuffered)
        )
      );
      setRevertedRows((prevState: any) =>
        prevState.filter((id: any) => id !== userGroupId)
      );
      setAddedUserIds((prevState: any) =>
        prevState.filter((id: any) => id !== userGroupId)
      );
      setEditedUserIds((prevState: any) =>
        prevState.filter((id: any) => id !== userGroupId)
      );
      mutateAccessList(updatedAccessList, false);
      mutateAccessList();
      // setAccessList(updatedAccessList);
      // fetchData();
    }
    mutateAccessList(accessList, false);
    // setAccessList(accessList);
    // console.log(`Row with ID ${userGroupId} reloaded`);
  };
  const reloadRow = (userGroupId: any) => {
    setRevertedRows((prevState: any) =>
      prevState.filter((id: any) => id !== userGroupId)
    );
    setAddedUserIds((prevState: any) =>
      prevState.filter((id: any) => id !== userGroupId)
    );
    setEditedUserIds((prevState: any) =>
      prevState.filter((id: any) => id !== userGroupId)
    );
  };
  const reloadButtonFunction = async () => {
    setIsReloadButtonClicked(false);
    mutateAccessList();
    // fetchData();
    let accessListforReload;
    setIsLoading(true);
    const updatedData = await FetchData({
      requestType: "noData",
      method: "GET",
      url: `workspace/access/${parentId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        // "X-TENANT-ID": "silzila",
      },
    });
    setIsLoading(false);
    accessListforReload = JSON.parse(JSON.stringify(updatedData.data));
    const uniqueUserGroupIds = new Set(revertedRows);
    uniqueUserGroupIds.forEach((userGroupId) => reloadRow(userGroupId));
    setIsReverted(false);
    setAddedUserIds([]);
    setEditedUserIds([]);
    showAlert("Action reloaded successfully!", "success");
    if (accessListforReload.length) {
      const permissions: any = {};
      accessListforReload.forEach((access: any) => {
        if (access.customPermissions && access.customPermissions.length > 0) {
          permissions[access.id] = {};
          access.customPermissions.forEach((permission: any) => {
            permissions[access.id][permission.contentTypeId] =
              permission.privilegeId;
          });
        }
      });
      setCustomPermissions(permissions);
    }
  };

  const sortedAndFilteredModalData = React.useMemo(() => {
    let sortedItems = [...filteredModalData];
    if (sortField.key) {
      sortedItems.sort((a, b) => {
        let aValue, bValue;
        if (sortField.key === "name") {
          aValue = a.name.toLowerCase();
          bValue = b.name.toLowerCase();
        } else if (sortField.key === "emailId") {
          aValue = a.emailId ? a.emailId.toLowerCase() : "";
          bValue = b.emailId ? b.emailId.toLowerCase() : "";
        }

        if (aValue < bValue) {
          return sortField.direction === "asc" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortField.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortedItems;
  }, [filteredModalData, sortField]);

  const handleSortModal = (key: string) => {
    setSortField((prev) => ({
      key,
      direction: prev.key === key && prev.direction === "asc" ? "desc" : "asc",
    }));
  };

  const getSortIconModal = (key: any) => {
    if (sortField.key === key) {
      return sortField.direction === "asc" ? (
        <img
          className="headerIcon-up-modal"
          src="/sort-up.png"
          alt="Ascending"
        />
      ) : (
        <img
          className="headerIcon-down-modal"
          src="/sort-down.png"
          alt="Descending"
        />
      );
    }
    return <img className="headerIcon-modal" src="/sort.png" alt="Default" />;
  };

  const Functions = () => {
    handleNewButtonClick();
    setModalContent("User");
  };
  const renderImage = (id: any) => {
    if (revertedRows.includes(id)) {
      return "/revert_active.png";
    } else if (!isReverted) {
      return "/revert_white.png";
    }
  };
  return (
    <div
      className="access-container"
      style={{ height: "100%", padding: "0 auto" }}
    >
      <div style={{ height: "8.6rem", overflowY: "hidden" }}>
        <div className={styles.headerContainer}>
          <button
            className={styles.backLink}
            style={{
              // position: "absolute",
              // left: 0,
              // top: "50%",
              // transform: "translateY(-50%)",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              padding: "0",
              fontSize: fontSize.medium,
              color: palette.primary.contrastText,
              gap: "0.5rem",
              fontWeight: "400",
            }}
            onClick={handleBackButton}
          >
            <ArrowBackIcon />
            Back
          </button>
          <div className={styles.header}>
            <h3
              style={{
                fontSize: fontSize.large,
                color: palette.primary.contrastText,
                marginRight: "7rem",
              }}
            >
              Manage Access
            </h3>
          </div>
        </div>

        <div className="heading">
          <img
            src="/folder_work.svg"
            style={{ width: "24px", height: "24px" }}
            alt="Folder Icon"
          ></img>
          {/* <div> */}
          <h3 style={{ marginLeft: "6px", marginTop: "0" }}>
            {parentWorkspaceName}
          </h3>
          {/* </div> */}
        </div>
        <div
          className="subworkspace-action-buttons"
          style={{ marginTop: "1.45rem", height: "2rem" }}
        >
          <button
            className={styles.createWorkspace}
            onClick={Functions}
            style={{
              // backgroundColor: palette.primary.main,
              fontSize: fontSize.medium,
              padding: "0.5rem 1rem",
              // marginTop: "1.875rem",
              // borderRadius: "5px",
              // border: "none",
              cursor: "pointer",
            }}
          >
            <img
              className={`${styles.adminAddIconDefault} addIconDefault`}
              src="/add_purple.png"
              alt="Add Users Logo"
              style={{ width: "16px", height: "16px" }}
            />
            <img
              className={`${styles.adminAddIconHover} addIconHover`}
              src="/add_white.png"
              alt="Add Users Logo"
              style={{ width: "16px", height: "16px" }}
            />
            Add
          </button>
        </div>
      </div>
      {/* style for 'access-table_loader_wrapper is defined in allPages.css */}
      <div className="access-table_loader_wrapper">
        {isLoading ? (
          <div
            className="loading-container"
            style={{
              height: "100%",
              width: "100%",
              border: "1px solid white",
              overflowY: "auto",
              overflowX: "hidden",
              marginTop: "0.5rem",
            }}
          >
            <div className="user-spinner"></div>
          </div>
        ) : accessList.length === 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              width: "100%",
              marginTop: "0.5rem",
            }}
          >
            <div
              style={{
                fontSize: fontSize.medium,
                color: palette.primary.contrastText,
              }}
            >
              Currently, no user/group have access to this particular workspace.
              <div>Please add user/groups via Add button.</div>
            </div>
          </div>
        ) : (
          // style for 'save_reload_table_wrapper' is in allPages.css
          <div className="save_reload_table_wrapper">
            <div
              className={`${styles.accesstableContainer} accesstableContainer`}
              style={{
                marginBottom: "0.2rem",
                height: "calc(100% - 4.6rem)",
              }}
            >
              <table className={`${styles.resourcesTable} resourcesTable`}>
                <thead>
                  <tr>
                    <th
                      style={{
                        color: palette.secondary.contrastText,
                        fontSize: fontSize.large,
                        backgroundColor: palette.primary.grey,
                      }}
                      rowSpan={3}
                    >
                      Actions
                    </th>
                    <th
                      style={{
                        color: palette.secondary.contrastText,
                        fontSize: fontSize.large,
                        backgroundColor: palette.primary.grey,
                      }}
                      rowSpan={3}
                    >
                      State
                    </th>
                    <th
                      style={{
                        color: palette.secondary.contrastText,
                        fontSize: fontSize.large,
                        backgroundColor: palette.primary.grey,
                      }}
                      rowSpan={3}
                    >
                      User/Group
                    </th>
                    <th
                      style={{
                        color: palette.secondary.contrastText,
                        fontSize: fontSize.large,
                        backgroundColor: palette.primary.grey,
                      }}
                      rowSpan={3}
                    >
                      Workspace Role
                    </th>
                    <th
                      style={{
                        color: palette.secondary.contrastText,
                        fontSize: fontSize.large,
                        backgroundColor: palette.primary.grey,
                      }}
                      colSpan={28}
                    >
                      Resources
                    </th>
                  </tr>
                  <tr>
                    <th
                      style={{
                        color: palette.secondary.contrastText,
                        fontSize: fontSize.large,
                        backgroundColor: palette.primary.grey,
                      }}
                      colSpan={4}
                    >
                      Sub Folder
                    </th>
                    <th
                      style={{
                        color: palette.secondary.contrastText,
                        fontSize: fontSize.large,
                        backgroundColor: palette.primary.grey,
                      }}
                      colSpan={4}
                    >
                      DB Connection
                    </th>
                    <th
                      style={{
                        color: palette.secondary.contrastText,
                        fontSize: fontSize.large,
                        backgroundColor: palette.primary.grey,
                      }}
                      colSpan={4}
                    >
                      Data Sets
                    </th>
                    <th
                      style={{
                        color: palette.secondary.contrastText,
                        fontSize: fontSize.large,
                        backgroundColor: palette.primary.grey,
                      }}
                      colSpan={4}
                    >
                      Data Files
                    </th>
                    <th
                      style={{
                        color: palette.secondary.contrastText,
                        fontSize: fontSize.large,
                        backgroundColor: palette.primary.grey,
                      }}
                      colSpan={4}
                    >
                      Playbook
                    </th>
                  </tr>
                  <tr>
                    {Array(5)
                      .fill(null)
                      .map((_, index) => (
                        <React.Fragment key={index}>
                          <Tooltip
                            title={
                              index === 4
                                ? "None is not allowed in Playbook"
                                : "None"
                            }
                            arrow
                          >
                            <th
                              style={{
                                color: palette.secondary.contrastText,
                                fontSize: fontSize.large,
                                backgroundColor: palette.primary.grey,
                              }}
                            >
                              <img
                                src="/none_header.png"
                                alt="None"
                                style={{ width: "17px", height: "17px" }}
                              ></img>
                            </th>
                          </Tooltip>
                          <Tooltip title="View" arrow>
                            <th
                              style={{
                                color: palette.secondary.contrastText,
                                fontSize: fontSize.large,
                                backgroundColor: palette.primary.grey,
                              }}
                            >
                              <img
                                src="/view_header.png"
                                alt="View"
                                style={{ width: "17px", height: "17px" }}
                              ></img>
                            </th>
                          </Tooltip>
                          <Tooltip title="Edit" arrow>
                            <th
                              style={{
                                color: palette.secondary.contrastText,
                                fontSize: fontSize.large,
                                backgroundColor: palette.primary.grey,
                              }}
                            >
                              <img
                                src="/edit_header.png"
                                alt="Edit"
                                style={{ width: "17px", height: "17px" }}
                              ></img>
                            </th>
                          </Tooltip>
                          <Tooltip title="Create" arrow>
                            <th
                              style={{
                                color: palette.secondary.contrastText,
                                fontSize: fontSize.large,
                                backgroundColor: palette.primary.grey,
                              }}
                            >
                              <img
                                src="/create_header.png"
                                alt="Create"
                                style={{ width: "17px", height: "17px" }}
                              ></img>
                            </th>
                          </Tooltip>
                        </React.Fragment>
                      ))}
                  </tr>
                </thead>
                <tbody>
                  {accessList.map((access: any) => (
                    <tr key={access.id}>
                      <td>
                        {/* Row reload disabled for now */}
                        {/* <button
                        className={styles.iconButton}
                        onClick={() => handleRowReload(access.id)}
                      >
                        <Tooltip title="Reload">
                          <img
                            src={renderImage(access.id)}
                            alt="Reload Row"
                            style={{ width: "17px", height: "17px" }}
                          />
                        </Tooltip>
                      </button> */}
                        <button
                          className={styles.iconButton}
                          onClick={() =>
                            handleDelete(
                              access.id,
                              access.name,
                              access.isGroup,
                              access.roleName
                            )
                          }
                        >
                          <Tooltip title="Delete">
                            <img
                              src="/delete_red.png"
                              alt="Delete User"
                              style={{ width: "17px", height: "17px" }}
                            ></img>
                          </Tooltip>
                        </button>
                      </td>
                      {/* <td className={styles.stateButton}>
                                            <Tooltip title="Add User">
                                                <img
                                                    src={addedUserIds.includes(access.id) ? "/add_access_active.png" : "/add_access.png"}
                                                    alt="Add Users Logo"
                                                    style={{ width: "17px", height: "17px"}}
                                                />
                                            </Tooltip>
                                            <Tooltip title="Edit User">
                                                <img
                                                    src={editedUserIds.includes(access.id) ? "/edit_access.png" : "/edit_access_white.png"}
                                                    alt="edit Users Logo"
                                                    style={{ width: "17px", height: "17px"}}
                                                />
                                            </Tooltip>
                                        </td> */}
                      <td>
                        {/* Render nothing if the user has no added/edited state */}
                        {!addedUserIds.includes(access.id) &&
                          !editedUserIds.includes(access.id) &&
                          !modifiedUsers.includes(access.id) && <span></span>}

                        {/* Show 'add' icon if the user was added */}
                        {addedUserIds.includes(access.id) && (
                          // !editedUserIds.includes(access.id) &&
                          <Tooltip title="Add User">
                            <img
                              src="/add_access_active.png"
                              alt="Add Users Logo"
                              width={17}
                              height={17}
                            />
                          </Tooltip>
                        )}

                        {/* Show 'edit' icon if the user was edited */}
                        {editedUserIds.includes(access.id) &&
                          !addedUserIds.includes(access.id) && (
                            <Tooltip title="Edit User">
                              <img
                                src="/edit_access.png"
                                alt="Edit Users Logo"
                                width={17}
                                height={17}
                              />
                            </Tooltip>
                          )}
                        {modifiedUsers.includes(access.id) && (
                          <Tooltip title="Edit User">
                            <img
                              src="/edit_access.png" // Path to the blue icon
                              alt="Modified Icon"
                              width={17}
                              height={17}
                            />
                          </Tooltip>
                        )}
                      </td>

                      <td
                        style={{
                          color: palette.primary.contrastText,
                          textAlign: "left",
                        }}
                      >
                        {access.name}
                      </td>
                      {/* <td>
                      <td style={{color:palette.primary.contrastText}}>{access.name}</td>
                      <td>
                        <select
                          className={styles.roleDropdown}
                          onChange={(e) =>
                            handleRoleChange(access.id, e.target.value)
                          }
                          value={access.role}
                        >
                          {roles.map((role, index) => (
                            <option key={index} value={role.value} style={{fontSize:fontSize.medium,color:palette.primary.contrastText}}>
                              {role.displayValue}
                            </option>
                          ))}
                        </select>
                      </td> */}
                      <td>
                        <FormControl
                          fullWidth
                          size="small"
                          sx={{
                            margin: 0,
                            textAlign: "left",
                            width: "8.125rem",
                          }}
                        >
                          <Select
                            value={access.role}
                            onChange={(e) =>
                              handleRoleChange(access.id, e.target.value)
                            }
                            displayEmpty
                            sx={{
                              padding: 0,
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#af99db !important",
                                },
                              "& .MuiSelect-select.css-1ind0d0-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                                {
                                  padding: "8.5px 5px !important",
                                },
                              // "& .MuiSelect-icon": {
                              //   color: "#af99db",
                              // },
                            }}
                          >
                            {roles.map((role, index) => (
                              <MenuItem
                                key={index}
                                value={role.value}
                                className={
                                  isAccessRoleDisabled(role.id)
                                    ? styles.disabledOption
                                    : ""
                                }
                                sx={{
                                  "&.Mui-selected": {
                                    backgroundColor:
                                      "rgba(175, 153, 219, 0.3) !important",
                                  },
                                  "&.Mui-selected:hover": {
                                    backgroundColor:
                                      "rgba(175, 153, 219, 0.5) !important",
                                  },
                                }}
                              >
                                {role.displayValue}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </td>

                      {Array(5)
                        .fill(null)
                        .map((_, index) => {
                          let privilegeId = 0;
                          if (index === 4) {
                            privilegeId =
                              customPermissions[access.id]?.[index + 1] || 3; // Default to 3 if no permission is set for playbook as none is not allowed for playbook
                          } else {
                            privilegeId =
                              customPermissions[access.id]?.[index + 1] || 0; // Default to 0 if no permission is set for other contents
                          }

                          return (
                            <React.Fragment key={access.id}>
                              <td colSpan={4}>
                                <StepSlider
                                  role={access.role}
                                  column={index + 1} // Start column IDs from 1
                                  initialValue={privilegeId} // Pass initial privilegeId to StepSlider
                                  hideCreate={false}
                                  onPermissionChange={(
                                    column: any,
                                    privilegeId: any
                                  ) =>
                                    handlePermissionChange(
                                      access.id,
                                      column,
                                      privilegeId
                                    )
                                  }
                                />
                              </td>
                            </React.Fragment>
                          );
                        })}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {/*If you change the height of below div make sure to change the height of the div having class name 'accesstable-container' as well  */}
            <div className={styles.tableButtons} style={{ height: "2rem" }}>
              <div>
                <button
                  className={styles.reloadButton}
                  onClick={() => setIsReloadButtonClicked(true)}
                  style={{
                    color: palette.primary.contrastText,
                    fontSize: fontSize.large,
                  }}
                >
                  Reload
                </button>
                <button
                  className={styles.okButton}
                  onClick={() => handleOkClick(accessList)}
                  style={{
                    color: palette.secondary.contrastText,
                    fontSize: fontSize.large,
                    backgroundColor: palette.secondary.light,
                  }}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <AcceptRejectDialog
        open={isReloadButtonClicked}
        closeFunction={() => setIsReloadButtonClicked(false)}
        acceptFunction={reloadButtonFunction}
        rejectFunction={() => setIsReloadButtonClicked(false)}
        heading="Are You Sure?"
        messages={[
          {
            text: "All the unsaved changes will be ignored. Do you still wto reload the page?",
          },
        ]}
        acceptText="Yes"
        rejectText="No"
        varient="animated"
        rejectButtonStyle={{
          border: "2px solid gray",
          color: "gray",
        }}
        acceptButtonStyle={{
          border: "2px solid #2bb9bb",
          color: "#2bb9bb",
        }}
        acceptTransition={{
          transitionTime: "0.2s",
          backgroundColor: "secondary.contrastText",
          color: "primary.main",
          hoverBackgroundColor: "primary.main",
          hoverColor: "secondary.contrastText",
        }}
        rejectTransition={{
          transitionTime: "0.2s",
          backgroundColor: "secondary.contrastText",
          color: "gray",
          hoverBackgroundColor: "gray",
          hoverColor: "secondary.contrastText",
        }}
      />

      {showUserDeleteConfirmation ? (
        <Dialog open={showUserDeleteConfirmation} onClose={cancelDelete}>
          <div className="access-remove-modal">
            <div className="access-remove-modalContent-close">
              <Tooltip title="Close">
                <CloseIcon
                  onClick={cancelDelete}
                  sx={{
                    fontSize: "large",
                    color: "#545454",
                    cursor: "pointer",
                    "&:hover": {
                      color: "red",
                    },
                  }}
                />
              </Tooltip>
            </div>
            <div className="access-remove-modalContent-p">
              <p>
                Are you sure you want to delete the{" "}
                {!isInGroup ? "user" : "group"} {user_GroupName}?
              </p>
            </div>

            <div className="access-remove-modalContent-Buttons">
              <button
                onClick={cancelDelete}
                className="access-remove-modalCancel"
              >
                Cancel
              </button>
              <button
                onClick={handleDeleteUser}
                className="access-remove-modalConfirm"
              >
                Confirm
              </button>
            </div>
          </div>
        </Dialog>
      ) : null}

      <Dialog open={isModalOpen} onClose={closeModal} maxWidth="md">
        <div className="accessListModal">
          <div className="accessListModalContent">
            <div className="accessListHeaderSection">
              <div className="accessListHeaderClose">
                <Tooltip title="Close">
                  <CloseIcon
                    onClick={closeModal}
                    sx={{
                      fontSize: "large",
                      color: "#545454",
                      cursor: "pointer",
                      "&:hover": {
                        color: "red",
                      },
                    }}
                  />
                </Tooltip>
              </div>

              <h3 className="access-modal-title">
                {modalContent === "User" ? "Select User" : "Select Group"}
              </h3>

              <div className={styles.adminListModalTabs}>
                <div className={styles.tabContainer}>
                  <div
                    onClick={() => setModalContent("User")}
                    className={`${styles.tab} ${
                      modalContent === "User" ? styles.activeTab : ""
                    }`}
                  >
                    Users
                  </div>

                  <div
                    onClick={() => setModalContent("Groups")}
                    className={`${styles.tab} ${
                      modalContent === "Groups" ? styles.activeTab : ""
                    }`}
                  >
                    Groups
                  </div>
                </div>
              </div>

              {modalContent === "User" && (
                <div className="accessListSearchContainer">
                  {/* User Search */}
                  <input
                    type="text"
                    placeholder="Search users"
                    className="accessListSearchInput"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                  <img
                    src="/glass.png"
                    alt="Search Icon"
                    className="accessListSearchIcon"
                  />
                </div>
              )}
              {modalContent === "Groups" && (
                <div className="adminListSearchContainer">
                  {/* Group Search */}
                  <input
                    type="text"
                    placeholder="Search groups"
                    className="accessListSearchInput"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                  <img
                    src="/glass.png"
                    alt="Search Icon"
                    className="accessListSearchIcon"
                  />
                </div>
              )}
            </div>

            <div className="accessListTableContainer">
              {modalContent === "User" && (
                <table
                  className={`${styles.accessListUserList} accessListUserList`}
                >
                  <thead>
                    <tr>
                      <th></th>
                      <th></th>
                      <th onClick={() => handleSortModal("name")}>
                        Name{" "}
                        <span className="icon-wrapper-modal">
                          {getSortIconModal("name")}
                        </span>
                      </th>
                      <th onClick={() => handleSortModal("emailId")}>
                        Email{" "}
                        <span className="icon-wrapper-modal">
                          {getSortIconModal("emailId")}
                        </span>
                      </th>
                    </tr>
                  </thead>

                  {sortedAndFilteredModalData.length === 0 &&
                  searchQuery.length !== 0 ? (
                    <div className="modal-content-no-user-info">
                      <p
                        className="loading-default-text"
                        style={{
                          fontSize: fontSize.medium,
                          color: palette.primary.contrastText,
                        }}
                      >
                        No user found.
                      </p>
                    </div>
                  ) : (
                    <tbody>
                      {sortedAndFilteredModalData
                        .filter(
                          (user: any) =>
                            !user.isGroup && !addedUserIds.includes(user.id)
                        )
                        .map((user: any) => (
                          <tr key={user.id} className={styles.accessUserItem}>
                            <td>
                              <input
                                type="radio"
                                className={styles.radioButton}
                                checked={selectedUsers.some(
                                  (selectedUser: any) =>
                                    selectedUser.id === user.id
                                )}
                                onChange={() =>
                                  handleUserSelection(
                                    user.id,
                                    user.isGroup,
                                    user.name
                                  )
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  user.profileImage
                                    ? `data:image/jpeg;base64,${user.profileImage}`
                                    : "/default.png"
                                }
                                alt="Users Icon"
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  borderRadius: "50%",
                                }}
                              />
                            </td>
                            <td>{user.name}</td>
                            <td>{user.emailId}</td>
                          </tr>
                        ))}
                    </tbody>
                  )}
                </table>
              )}

              {modalContent === "Groups" && (
                <table
                  className={`${styles.accessListGroupList} accessListGroupList`}
                >
                  <thead>
                    <tr>
                      <th></th>
                      <th></th>
                      <th onClick={() => handleSortModal("name")}>
                        Name{" "}
                        <span className="icon-wrapper-modal">
                          {getSortIconModal("name")}
                        </span>
                      </th>
                    </tr>
                  </thead>

                  {sortedAndFilteredModalData.length === 0 &&
                  searchQuery.length !== 0 ? (
                    <div className="modal-content-no-user-info">
                      <p
                        className="loading-default-text"
                        style={{
                          fontSize: fontSize.medium,
                          color: palette.primary.contrastText,
                        }}
                      >
                        No user found.
                      </p>
                    </div>
                  ) : (
                    <tbody>
                      {sortedAndFilteredModalData
                        .filter(
                          (group: any) =>
                            group.isGroup && !addedUserIds.includes(group.id)
                        )
                        .map((group: any) => (
                          <tr key={group.id} className={styles.accessUserItem}>
                            <td>
                              <input
                                type="radio"
                                className={styles.radioButton}
                                checked={selectedUsers.some(
                                  (selectedUser: any) =>
                                    selectedUser.id === group.id
                                )}
                                onChange={() =>
                                  handleUserSelection(
                                    group.id,
                                    group.isGroup,
                                    group.name
                                  )
                                }
                              />
                            </td>
                            <td>
                              <img
                                src="/groups.png"
                                alt="Group Icon"
                                style={{ width: "17px", height: "17px" }}
                              />
                            </td>
                            <td>{group.name}</td>
                          </tr>
                        ))}
                    </tbody>
                  )}
                </table>
              )}
            </div>
            
              <div className="accessListFooterSection">
                <HoverButton
                onClick={handleAdd}
                text="Add"
                color="secondary.light"
                hoverColor="secondary.contrastText"
                backgroundColor="secondary.contastText"
                hoverBackgroundColor="secondary.light"
                sx={{
                  ...ffButtonStyle,
                  border: `1px solid ${palette.secondary.light}`,
                  fontSize: fontSize.medium,
                  lineHeight: "normal",
                  borderRadius: "5px",
                  padding: "10px 20px",
                  margin: "0"
                }}
                transitionTime="0.2s"
              />
            </div>
          </div>
        </div>
      </Dialog>

      <NotificationDialog
        openAlert={openAlert}
        severity={severity}
        testMessage={testMessage}
        onCloseAlert={() => {
          setOpenAlert(false);
          setTestMessage("");
        }}
      />
    </div>
  );
};
export default Access;
