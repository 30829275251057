"use client";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./User.css";
import "./allPages.css";
import { AlertColor, Dialog, Tooltip } from "@mui/material";
import axios from "axios";
import { serverEndPoint } from "./ServerCall/EnvironmentVariables";
import FetchData from "./ServerCall/FetchData";
import CloseIcon from "@mui/icons-material/Close";
import { fontSize, palette } from "..";
import { NotificationDialog } from "./CommonFunctions/DialogComponents";

interface User {
  id: string;
  firstName: string;
  email: string;
  isDeactivated: boolean;
  isDeleted: boolean;
  profileImage: string | null;
}

const Users = () => {
  const [users, setUsers] = useState<any>([]);
  const [selectedUsers, setSelectedUsers] = useState<any>([]);
  const [searchQuery, setSearchQuery] = useState<any>("");
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
  const [hoveredIndex, setHoveredIndex] = useState<any>(-1);
  const [groupName, setGroupName] = useState<any>("");
  const [showCreateGroupDialog, setShowCreateGroupDialog] =
    useState<any>(false);
  const [sortConfig, setSortConfig] = useState<any>({
    key: "firstName",
    direction: "asc",
  });
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<any>(false);
  const [userIndexToDelete, setUserIndexToDelete] = useState<any>(null);
  const [showDeactivateConfirmation, setShowDeactivateConfirmation] =
    useState<any>(false);
  const [userIndexToDeactivate, setUserIndexToDeactivate] = useState<any>(null);
  const [showAlreadyDeactivatedDialog, setShowAlreadyDeactivatedDialog] =
    useState<any>(false);
  const [userToDelete, setUserToDelete] = useState<any>(null);
  const [userToDeactivate, setUserToDeactivate] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<any>(true);
  const [selectAll, setSelectAll] = useState<boolean>(false); // Add state for Select All checkbox
  const [error, setError] = useState<string | null>(null);
  const [userState, setUserState] = useState<string | null>("deactivate");
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [testMessage, setTestMessage] = useState<string>("");
  const [severity, setSeverity] = useState<AlertColor>("success");

  // const accessToken = localStorage.getItem('accessToken');

  // const axiosInstance = axios.create({
  //     headers: {
  //         'Authorization': `Bearer ${accessToken}`,
  //         'X-TENANT-ID': 'silzila',
  //         'Content-type': 'application/json'
  //     }
  // });

  // useEffect(() => {
  //     const fetchUsers = async () => {
  //         setIsLoading(true); // Start loading state
  //         setError(null);
  //         try {
  //             // Fetch user list first
  //             const response = await axiosInstance.get('https://dev.silzila.com/api/users/list');

  //             // Prepare image requests for all users in parallel
  //             const usersWithProfileImages = response.data.map((user: any) => {
  //                 if (!user.profileImage) {
  //                     return { ...user, profileImage: "/default.png" };
  //                 } else {
  //                     const profileImageUrl = `data:image/jpeg;base64,${user.profileImage}`;
  //                     return { ...user, profileImage: profileImageUrl };
  //                 }
  //             });

  //             // Set the processed user list
  //             setUsers(usersWithProfileImages);
  //         } catch (error) {
  //             console.error('Error fetching users:', error);
  //             showAlert('Error fetching users. Please try again.');
  //         } finally {
  //             setIsLoading(false);
  //         }
  //     };

  //     fetchUsers();
  // }, []);

  useEffect(() => {
    const fetchUsers = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const response = await FetchData({
          requestType: "noData",
          method: "GET",
          url: "users/list",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            // "X-TENANT-ID": "silzila",
          },
        });

        if (response.status) {
          const usersWithProfileImages = response.data.map((user: any) => {
            if (!user.profileImage) {
              return { ...user, profileImage: "/default.png" };
            } else {
              const profileImageUrl = `data:image/jpeg;base64,${user.profileImage}`;
              return { ...user, profileImage: profileImageUrl };
            }
          });

          setUsers(usersWithProfileImages);
        } else {
          if (
            !(
              response.data &&
              response.data.message &&
              response.data.message.includes("No value present")
            )
          )
            showAlert("Error fetching users. Please try again.", "error");
        }
      } catch (error) {
        console.error("Error fetching users:", error);
        showAlert("Error fetching users. Please try again.", "error");
      } finally {
        setIsLoading(false);
      }
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    const filterUsers = () => {
      const lowerCaseQuery = searchQuery.toLowerCase();
      return users.filter((user: any) => {
        const firstName = user.firstName?.toLowerCase() || "";
        const email = user.email?.toLowerCase() || "";
        return (
          firstName.includes(lowerCaseQuery) || email.includes(lowerCaseQuery)
        );
      });
    };

    const sortedAndFilteredUsers = () => {
      let filtered = filterUsers();
      filtered = [...filtered].filter((item) => item.isDeleted === false);

      if (sortConfig.key) {
        return filtered.sort((a: any, b: any) => {
          let aValue, bValue;
          if (sortConfig.key === "firstName") {
            aValue = a.firstName.toLowerCase() || "";
            bValue = b.firstName.toLowerCase() || "";
          } else if (sortConfig.key === "email") {
            aValue = a.email.toLowerCase() || "";
            bValue = b.email.toLowerCase() || "";
          } else if (sortConfig.key === "status") {
            aValue = a.isDeactivated ? 1 : 0;
            bValue = b.isDeactivated ? 1 : 0;
          }

          if (aValue < bValue) {
            return sortConfig.direction === "asc" ? -1 : 1;
          }
          if (aValue > bValue) {
            return sortConfig.direction === "asc" ? 1 : -1;
          }
          return 0;
        });
      }
      return filtered;
    };

    setFilteredUsers(sortedAndFilteredUsers());
  }, [searchQuery, users, sortConfig]);

  const showAlert = (message: string, severity: AlertColor) => {
    setTestMessage(message);
    setSeverity(severity);
    setOpenAlert(true);
    setTimeout(() => {
      setOpenAlert(false);
    }, 3000);
  };

  const deleteUser = (id: any) => {
    const user = users.find((user: any) => user.id === id);
    if (!user) {
      console.error("User not found");
      return;
    }
    setUserToDelete(user);
    setShowDeleteConfirmation(true);
  };

  // const confirmDeleteUser = async () => {
  //     try {
  //         if (!userToDelete || !userToDelete.id) throw new Error("User ID is missing");

  //         await axiosInstance.delete(`https://dev.silzila.com/api/user/delete/${userToDelete.id}`);
  //         const updatedUsers = users.filter((user: any) => user.id !== userToDelete.id);
  //         setUsers(updatedUsers);
  //     } catch (error) {
  //         console.error('There was an error deleting the user:', error);
  //         showAlert('Error deleting user. Please try again.');
  //     } finally {
  //         setShowDeleteConfirmation(false);
  //         setUserIndexToDelete(null);
  //     }
  // };
  const confirmDeleteUser = async () => {
    try {
      if (!userToDelete || !userToDelete.id)
        throw new Error("User ID is missing");

      setIsLoading(true);
      setShowDeleteConfirmation(false);
      const response = await FetchData({
        requestType: "noData",
        method: "DELETE",
        url: `user/delete/${userToDelete.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          // 'X-TENANT-ID': 'silzila',
        },
      });

      if (response.status) {
        const updatedUsers = users.filter(
          (user: any) => user.id !== userToDelete.id
        );
        setUsers(updatedUsers);
        showAlert("User deleted successfully", "success");
      } else {
        showAlert("Error deleting user. Please try again.", "error");
      }
    } catch (error) {
      console.error("There was an error deleting the user:", error);
      showAlert("Error deleting user. Please try again.", "error");
    } finally {
      setIsLoading(false);
      setShowDeleteConfirmation(false);
      setUserIndexToDelete(null);
    }
  };

  const cancelDelete = () => {
    setShowDeleteConfirmation(false);
    setUserIndexToDelete(null);
  };

  const deactivateUser = (id: any, state: boolean = false) => {
    const userToDeactivate = users.find((user: any) => user.id === id);
    if (!userToDeactivate) {
      console.error("User not found.");
      return;
    }

    setUserState(state ? "activate" : "deactivate");

    // if (!userToDeactivate.isDeactivated) {
    setUserToDeactivate(userToDeactivate);
    setShowDeactivateConfirmation(true);
    // } else {
    //     //setShowAlreadyDeactivatedDialog(true);
    // }
  };

  const confirmDeactivateUser = async () => {
    try {
      if (!userToDeactivate.id) throw new Error("User ID is missing");

      let isActive = userState === "deactivate";

      setIsLoading(true);
      setShowDeactivateConfirmation(false);
      const response = await FetchData({
        requestType: "noData",
        method: "PUT",
        url: `user/deactivate?userId=${userToDeactivate.id}&deactivate=${isActive}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          // 'X-TENANT-ID': 'silzila',
        },
      });

      if (response.status) {
        setUsers((prevUsers: any) =>
          prevUsers.map((user: any) =>
            user.id === userToDeactivate.id
              ? { ...user, isDeactivated: isActive }
              : user
          )
        );
        showAlert("User deactivated successfully", "success");
      } else {
        showAlert("Error deactivating user. Please try again.", "error");
      }
    } catch (error) {
      console.error("There was an error deactivating the user:", error);
      showAlert("Error deactivating user. Please try again.", "error");
    } finally {
      setIsLoading(false);
      setShowDeactivateConfirmation(false);
      setUserIndexToDeactivate(null);
    }
  };

  const cancelDeactivate = () => {
    setShowDeactivateConfirmation(false);
    setUserIndexToDeactivate(null);
  };

  const closeAlreadyDeactivatedDialog = () => {
    setShowAlreadyDeactivatedDialog(false);
  };

  const toggleSelectUser = (index: any) => {
    if (selectedUsers.includes(index)) {
      setSelectedUsers(selectedUsers.filter((i: any) => i !== index));
    } else {
      setSelectedUsers([...selectedUsers, index]);
    }
  };

  const selectAllUsers = () => {
    // Set the selected users to all the indexes of the filtered users
    setSelectedUsers(filteredUsers.map((_, index) => index)); // Here we're using `index` to track position in filteredUsers
    setSelectAll(true);
  };

  const handleDeselectAll = () => {
    setSelectedUsers([]);
    setSelectAll(false);
  };

  const handleMouseEnter = (index: any) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(-1);
  };

  const handleCreateGroup = () => {
    setShowCreateGroupDialog(true);
  };

  const handleGroupNameChange = (e: any) => {
    setGroupName(e.target.value);
  };

  const handleCloseDialog = () => {
    setShowCreateGroupDialog(false);
    setGroupName("");
  };

  // const handleCreateGroupConfirm = async () => {
  //     const userIds = selectedUsers.map((index:any) => users[index].id);
  //     const requestBody = {
  //         userIds: userIds,
  //         groupName: groupName
  //     };

  //     try {
  //         await axiosInstance.post('https://dev.silzila.com/api/group-users/create', requestBody);
  //         console.log(`Group "${groupName}" created successfully with users:`, userIds);
  //         setShowCreateGroupDialog(false);
  //         setGroupName('');
  //         setSelectedUsers([]);
  //     } catch (error) {
  //         console.error('Error creating group:', error);
  //         showAlert('Error creating group. Please try again.');
  //     }
  // };
  const handleCreateGroupConfirm = async () => {
    const userIds = selectedUsers.map((index: any) => users[index].id);
    const requestBody = {
      userIds: userIds,
      groupName: groupName,
    };

    try {
      // Using FetchData to create the group
      const response = await FetchData({
        requestType: "withData",
        method: "POST",
        url: "group-users/create",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          // "X-TENANT-ID": "silzila",
        },
        data: requestBody,
      });

      if (response.status) {
        console.log(
          `Group "${groupName}" created successfully with users:`,
          userIds
        );
        setShowCreateGroupDialog(false);
        setGroupName("");
        setSelectedUsers([]);
      } else {
        showAlert("Error creating group. Please try again.", "error");
      }
    } catch (error) {
      console.error("Error creating group:", error);
      showAlert("Error creating group. Please try again.", "error");
    }
  };

  const handleSort = (key: any) => {
    const newDirection =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key, direction: newDirection });
  };

  const getSortIcon = (key: any) => {
    if (sortConfig.key === key) {
      if (key === "status") {
        return sortConfig.direction === "asc" ? (
          <img
            className="user-headerIcon"
            src="/status_activated.png"
            alt="Status Ascending"
            style={{ width: "17px", height: "17px" }}
          />
        ) : (
          <img
            className="user-headerIcon"
            src="/status_deactivated.png"
            alt="Status Descending"
            style={{ width: "17px", height: "17px" }}
          />
        );
      } else {
        return sortConfig.direction === "asc" ? (
          <img className="headerIcon-up" src="/sort-up.png" alt="Ascending" />
        ) : (
          <img
            className="headerIcon-down"
            src="/sort-down.png"
            alt="Descending"
          />
        );
      }
    }
    return <img className="headerIcon" src="/sort.png" alt="Default" />;
  };

  if (error)
    return (
      <div className="loading-error-info">
        <p
          className="loading-default-text"
          style={{
            fontSize: fontSize.medium,
            color: palette.primary.contrastText,
          }}
        >
          Error loading Users.
        </p>
      </div>
    );

  return (
    <div className="user-container">
      <div
        className="heading"
        style={{
          height: "50px",
          border: "1px solid transparent",
        }}
      >
        <img
          src="/user.png"
          alt="Users"
          style={{ width: "24px", height: "24px" }}
        />
        <div>
          <h3
            style={{
              // marginTop: "-4px",
              // marginBottom: "5px",
              padding: "0px",
              margin: "0px",
              marginLeft: "6px",
              fontSize: fontSize.large,
              color: palette.primary.contrastText,
            }}
          >
            Users
          </h3>
        </div>
      </div>

      <div className="user-button-add-search-container">
        <div className="user-actionButtons">
          {selectedUsers.length === 0 ? (
            <Link to="/users/add" className="user-linkButton">
              <button
                className="user-button"
                style={{ fontSize: fontSize.medium }}
                // onMouseOver={}
              >
                {/* <img className='addIcon'src="/add_white.png" alt="Add Users Logo" width={20} height={20} /> */}
                <img
                  className="user-addIcon user-addIconDefault"
                  src="/add_green.png"
                  alt="Add Users Logo"
                  style={{ width: "16px", height: "16px", marginTop: "3px" }}
                />
                <img
                  className="user-addIcon user-addIconHover"
                  src="/add_white.png"
                  alt="Add Users Logo Hover"
                  style={{ width: "16px", height: "16px", marginTop: "3px" }}
                />
                Add Users
              </button>
            </Link>
          ) : (
            <button
              className="user-button1"
              onClick={handleCreateGroup}
              style={{
                color: palette.primary.contrastText,
                fontSize: fontSize.medium,
              }}
            >
              <img
                className="user-addIcon user-addIconDefault"
                src="/groups_green.png"
                alt="Create Group"
                style={{ width: "16px", height: "16px", marginTop: "3px" }}
              />
              <img
                className="user-addIcon user-addIconHover"
                src="/groups_white.png"
                alt="Create Group"
                style={{ width: "16px", height: "16px", marginTop: "3px" }}
              />
              Create Group
            </button>
          )}
        </div>

        <div className="user-select-action-container">
          <div className="user-actions-container">
            {/* Future Update
                    <p  onClick={selectAllUsers}>
                    Select All
                    </p>

                    {selectedUsers.length > 0 && (
                    <div className="user-actions-count-container">    
                    <p onClick={handleDeselectAll} style={{ cursor: 'pointer', marginLeft: "10px", border: "1px solid rgb(201, 201, 201)", }}>Deselect All</p>
                    <span>{selectedUsers.length > 0 && `${selectedUsers.length} selected`}</span>
                    <p style={{ marginLeft: "10px", border: "1px solid rgb(201, 201, 201)", }}>Actions</p>
                    </div>)} */}
          </div>

          <div className="user-searchContainer">
            <input
              type="text"
              placeholder="Search users"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="user-searchInput"
              style={{
                fontSize: fontSize.medium,
                color: palette.primary.contrastText,
              }}
            />
            <img
              src="/glass.png"
              alt="Search Icon"
              className="user-searchIcon"
            />
          </div>
        </div>
      </div>

      {isLoading ? (
        <div className="loading-container">
          <div className="user-spinner"></div>
        </div>
      ) : users.length === 0 && searchQuery.length === 0 ? (
        <div className="no-user-info">
          <p
            className="loading-default-text"
            style={{
              fontSize: fontSize.medium,
              color: palette.primary.contrastText,
            }}
          >
            You have not added any users in your organisation. Click here to{" "}
            <Link to="/users/add" className="link">
              {" "}
              Add Users
            </Link>
          </p>
        </div>
      ) : (
        // {users.length === 0 ? (
        //     <p className='default-text'>You have not added any users in your organisation. Click here to <Link className="link" to="/add-users">Add Users</Link></p>
        // ) : (
        <div className="user-tableContainer">
          <table className="user-userTable">
            <thead style={{ backgroundColor: "white" }}>
              <tr>
                <th></th>

                <th></th>

                <th onClick={() => handleSort("firstName")}>
                  Name{" "}
                  <span className="icon-wrapper">
                    {getSortIcon("firstName")}
                  </span>
                </th>

                <th onClick={() => handleSort("email")}>
                  Email{" "}
                  <span className="icon-wrapper">{getSortIcon("email")}</span>
                </th>

                <th onClick={() => handleSort("status")}>
                  Status{" "}
                  <span className="icon-wrapper">{getSortIcon("status")}</span>
                </th>

                <th style={{ paddingRight: "11px" }}>Actions</th>
              </tr>
            </thead>

          {filteredUsers.length === 0 && searchQuery.length !== 0 ? (
              <div className="no-user-info-insideTable">
                <p
                  className="loading-default-text"
                  style={{
                    fontSize: fontSize.medium,
                    color: palette.primary.contrastText,
                  }}
                >
                  No user found.
                </p>
                </div>
              ) : (
              <tbody>
                {filteredUsers.map((user: any, index: any) => (
                  <tr
                    key={index}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                    className={
                      selectedUsers.includes(index) ? "selectedRow" : ""
                    }
                  >
                    <td>
                      {/* <input 
                                type="checkbox"
                                checked={selectedUsers.includes(index)}
                                onChange={() => toggleSelectUser(index)}
                                style={{width: "16px", height: "16px"}}
                            /> */}
                    </td>

                    <td>
                      <img
                        src={
                          user.profileImage ? user.profileImage : "/default.png"
                        }
                        alt={`${user.firstName}`}
                        style={{
                          width: "20px",
                          height: "20px",
                          borderRadius: "50%",
                        }}
                      />
                    </td>

                    <td
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "150px",
                      }}
                    >
                      {user.isAdmin ? (
                        <Tooltip title="Admin">
                          <img
                            src="/crown.png"
                            alt="Admin Icon"
                            style={{
                              transform: "translateY(3px)",
                              width: "15px",
                              height: "15px",
                              marginRight: "15px",
                            }}
                          />
                        </Tooltip>
                      ) : (
                        <span
                          style={{
                            display: "inline-block",
                            width: "15px",
                            height: "15px",
                            marginRight: "15px",
                          }}
                        ></span>
                      )}
                      {user.firstName}
                    </td>

                    <td
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "150px",
                      }}
                    >
                      {user.email}
                    </td>

                    <td>
                      <div>
                        <Tooltip
                          title={
                            user.isDeactivated
                              ? "Deactivated User"
                              : "Active User"
                          }
                          arrow
                        >
                          <img
                            src={user.isDeactivated ? "/cross.png" : "/yes.png"}
                            alt={user.isDeactivated ? "Deactivated" : "Active"}
                            style={{ width: "17px", height: "17px" }}
                          />
                        </Tooltip>
                      </div>
                    </td>

                    <td>
                      <div className="user-img-icon">
                        <button
                          onClick={() => deleteUser(user.id)}
                          style={{
                            background: "none",
                            border: "none",
                            padding: "0",
                          }}
                        >
                          <Tooltip title="Delete User">
                            <img
                              src={
                                hoveredIndex === index
                                  ? "/delete_red.png "
                                  : "/delete_white.png"
                              }
                              alt="Delete User"
                              style={{
                                width: "17px",
                                height: "17px",
                                padding: "0",
                              }}
                            />
                          </Tooltip>
                        </button>

                        {user.isDeactivated ? (
                          <button
                            onClick={() => deactivateUser(user.id, true)}
                            style={{
                              background: "none",
                              border: "none",
                              padding: "0",
                            }}
                          >
                            <Tooltip title="Activate User">
                              <img
                                src={
                                  hoveredIndex === index
                                    ? "/activate_user_active.png"
                                    : "/activate_user_inactive.png"
                                }
                                alt="Activate User"
                                style={{
                                  width: "17px",
                                  height: "17px",
                                  padding: "0",
                                }}
                              />
                            </Tooltip>
                          </button>
                        ) : (
                          <button
                            onClick={() => deactivateUser(user.id)}
                            style={{
                              background: "none",
                              border: "none",
                              padding: "0",
                            }}
                          >
                            <Tooltip title="Deactivate User">
                              <img
                                src={
                                  hoveredIndex === index
                                    ? "/deactivate_orange.png"
                                    : "/deactivate_white.png"
                                }
                                alt="Deactivate User"
                                style={{
                                  width: "17px",
                                  height: "17px",
                                  padding: "0",
                                }}
                              />
                            </Tooltip>
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
            )}
          </table>
        </div>
      )}


      {showCreateGroupDialog && (
        <div className="user-modal">
          <div className="user-modal-content">
            <span className="user-close" onClick={handleCloseDialog}>
              &times;
            </span>
            <p
              style={{
                fontSize: fontSize.medium,
                color: palette.primary.contrastText,
              }}
            >
              Please name the group:
            </p>
            <input
              type="text"
              required
              value={groupName}
              onChange={handleGroupNameChange}
              style={{
                fontSize: fontSize.medium,
                color: palette.primary.contrastText,
              }}
            />
            <div className="user-modal-buttons">
              <button
                onClick={handleCloseDialog}
                className="user-modal-cancel"
                style={{
                  fontSize: fontSize.medium,
                  color: palette.primary.contrastText,
                }}
              >
                Cancel
              </button>
              <button
                onClick={handleCreateGroupConfirm}
                style={{
                  fontSize: fontSize.medium,
                  color: palette.primary.contrastText,
                }}
              >
                Create Group
              </button>
            </div>
          </div>
        </div>
      )}

      <Dialog open={showDeleteConfirmation} onClose={cancelDelete}>
        <div className="user-remove-modal">
          <div className="user-remove-modalContent-close" style={{visibility: "hidden"}}>
            <Tooltip title="Close">
              <CloseIcon
                onClick={() => setShowDeleteConfirmation(false)}
                sx={{
                  fontSize: "large",
                  color: "#545454",
                  cursor: "pointer",
                  "&:hover": {
                    color: "red",
                  },
                }}
              />
            </Tooltip>
          </div>
          <div className="user-remove-modalContent-p">
            <p>Are you sure you want to delete '{userToDelete?.firstName}' ?</p>
          </div>

          <div className="user-remove-modalContent-Buttons">
            <button
              onClick={cancelDelete}
              className="user-remove-modalCancel"
              style={{
                fontSize: fontSize.medium,
              }}
            >
              Cancel
            </button>
            <button
              onClick={confirmDeleteUser}
              className="user-remove-modalConfirm"
              style={{ fontSize: fontSize.medium }}
            >
              Confirm
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog open={showDeactivateConfirmation} onClose={cancelDeactivate}>
        <div className="user-remove-modal">
          <div className="user-remove-modalContent-close">
            <Tooltip title="Close">
              <CloseIcon
                onClick={() => setShowDeactivateConfirmation(false)}
                sx={{
                  fontSize: "large",
                  color: "#545454",
                  cursor: "pointer",
                  "&:hover": {
                    color: "red",
                  },
                }}
              />
            </Tooltip>
          </div>
          <div className="user-remove-modalContent-p">
            <p>
              Are you sure you want to {userState} '
              {userToDeactivate?.firstName}' ?
            </p>
          </div>

          <div className="user-remove-modalContent-Buttons">
            <button
              onClick={cancelDeactivate}
              className="user-remove-modalCancel"
              style={{
                fontSize: fontSize.medium,
              }}
            >
              Cancel
            </button>
            <button
              onClick={confirmDeactivateUser}
              className="user-remove-modalConfirm"
              style={{ fontSize: fontSize.medium }}
            >
              Confirm
            </button>
          </div>
        </div>
      </Dialog>

      {showAlreadyDeactivatedDialog && (
        <div className="user-modal">
          <div className="user-modal-content">
            <span
              className="user-close"
              onClick={closeAlreadyDeactivatedDialog}
            >
              &times;
            </span>
            <p
              style={{
                fontSize: fontSize.medium,
                color: palette.primary.contrastText,
              }}
            >
              This user is already deactivated.
            </p>
          </div>
        </div>
      )}

      <NotificationDialog
        openAlert={openAlert}
        severity={severity}
        testMessage={testMessage}
        onCloseAlert={() => {
          setOpenAlert(false);
          setTestMessage("");
        }}
      />
    </div>
  );
};

export default Users;
