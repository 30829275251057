import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import useSWR from "swr";
import "./groups.css";
import "./allPages.css";
import { Link } from "react-router-dom";
import { AlertColor, Dialog, Tooltip } from "@mui/material";
import { serverEndPoint } from "./ServerCall/EnvironmentVariables";
import CloseIcon from "@mui/icons-material/Close";
import FetchData from "./ServerCall/FetchData";
import { fontSize, palette } from "..";
import { NotificationDialog } from "./CommonFunctions/DialogComponents";

const GroupName = () => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState<any>("");
  const [hoveredIndex, setHoveredIndex] = useState<any>(-1);
  const [sortConfig, setSortConfig] = useState<any>({
    key: "groupName",
    direction: "asc",
  });
  const [groupIndexToDelete, setGroupIndexToDelete] = useState<any>(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<any>(false);
  const [groupToDelete, setGroupToDelete] = useState<any>(null);
  const [groupToRename, setGroupToRename] = useState<any>(null);
  const [newGroupName, setNewGroupName] = useState<any>("");
  const [showCreateGroupDialog, setShowCreateGroupDialog] =
    useState<any>(false);
  const [renameError, setRenameError] = useState<any>("");
  const [isLoading, setIsLoading] = useState<any>(true);
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [testMessage, setTestMessage] = useState<string>("");
  const [severity, setSeverity] = useState<AlertColor>("success");

  const [groups, setGroups] = useState<any[]>([]);
  const [error, setError] = useState<any>(null);

  // const accessToken = localStorage.getItem('accessToken');

  // const fetcher = (url: any, axiosInstance: any) => axiosInstance.get(url).then((res: any) => res.data);

  // const axiosInstance = axios.create({
  //     headers: {
  //         'Authorization': `Bearer ${accessToken}`,
  //         'X-TENANT-ID': 'silzila',
  //         'Content-type': 'application/json'
  //     }
  // });

  // const { data: groups = [], mutate, error } = useSWR('https://dev.silzila.com/api/groups/list', url => fetcher(url, axiosInstance));

  const fetchGroups = async () => {
    setIsLoading(true);
    const response = await FetchData({
      requestType: "noData",
      method: "GET",
      url: `groups/list`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "Content-Type": "application/json",
        // "X-TENANT-ID": "silzila",
      },
    });

    if (response.status) {
      setGroups(response.data);
      setError(null);
    } else {
      setError(response.data);
      setGroups([]);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    fetchGroups();
  }, []);
  // useEffect(() => {
  //     if (groups.length > 0 || groups.length === 0) {
  //         setIsLoading(false);
  //         console.log(groups);
  //     }
  // }, [groups]);

  if (error) {
    setIsLoading(false);
    console.error("Error fetching groups:", error);
  }

  const filteredGroups: any = groups
    .filter((group: any) =>
      group.groupName.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .sort((a: any, b: any) => {
      if (a.id === 1) return -1;
      if (b.id === 1) return 1;
      let aValue, bValue;
      if (sortConfig.key === "groupName") {
        aValue = a.groupName.toLowerCase();
        bValue = b.groupName.toLowerCase();
      } else if (sortConfig.key === "userCount") {
        aValue = a.userCount;
        bValue = b.userCount;
      }
      if (aValue < bValue) {
        return sortConfig.direction === "asc" ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === "asc" ? 1 : -1;
      }
      return 0;
    });

  const showAlert = (message: string, severity: AlertColor) => {
    setTestMessage(message);
    setSeverity(severity);
    setOpenAlert(true);
    setTimeout(() => {
      setOpenAlert(false);
    }, 3000);
  };

  const deleteGroup = (index: any) => {
    // console.log(index,,groups[index]);
    setGroupIndexToDelete(index);
    setGroupToDelete(filteredGroups[index]);
    setShowDeleteConfirmation(true);
  };

  // const confirmDeleteGroup = async () => {
  //     try {
  //         const groupToDelete = filteredGroups[groupIndexToDelete];
  //         if (!groupToDelete.id) throw new Error("Group ID is missing");

  //         await axiosInstance.delete(`https://dev.silzila.com/api/groups/${groupToDelete.id}`);
  //         mutate();
  //     } catch (error) {
  //         console.error('There was an error deleting the group:', error);
  //         showAlert('Error deleting group. Please try again.');
  //     } finally {
  //         setShowDeleteConfirmation(false);
  //         setGroupIndexToDelete(null);
  //     }
  // };
  const confirmDeleteGroup = async () => {
    try {
      const groupToDelete = filteredGroups[groupIndexToDelete];
      if (!groupToDelete.id) throw new Error("Group ID is missing");

      // Replace axiosInstance.delete with FetchData
      setIsLoading(true);
      setShowDeleteConfirmation(false);
      await FetchData({
        requestType: "noData", // No data is returned in the response
        method: "DELETE", // HTTP method
        url: `groups/${groupToDelete.id}`, // API endpoint with group ID
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          // "X-TENANT-ID": "silzila",
          "Content-Type": "application/json",
        },
      });
      showAlert("Group deleted successfully", "success");

      // mutate();
      fetchGroups();
    } catch (error: any) {
      console.error("There was an error deleting the group:", error);
      showAlert("Error deleting group. Please try again.", "error");
    } finally {
      setIsLoading(false);
      setShowDeleteConfirmation(false);
      setGroupIndexToDelete(null);
    }
  };

  const cancelDelete = () => {
    setShowDeleteConfirmation(false);
    setGroupIndexToDelete(null);
  };

  const handleMouseEnter = (index: any) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(-1);
  };

  const handleGroupClick = (groupid: any, groupName: any) => {
    navigate(`/group-details/${groupid}`, { state: { groupName } });
    console.log(groupName);
  };

  const handleSort = (key: any) => {
    const newDirection =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key, direction: newDirection });
  };

  const getSortIcon = (key: any) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "asc" ? (
        <img className="headerIcon-up" src="/sort-up.png" alt="Ascending" />
      ) : (
        <img
          className="headerIcon-down "
          src="/sort-down.png"
          alt="Descending"
        />
      );
    }
    return <img className="headerIcon" src="/sort.png" alt="Default" />;
  };

  // const handleCreateGroup = async () => {
  //     if (!newGroupName.trim()) {
  //         showAlert('Group name cannot be empty.');
  //         return;
  //     }

  //     try {
  //         await axiosInstance.post(`https://dev.silzila.com/api/groups/${newGroupName}`);
  //         mutate(); // Refetch the groups to update the list

  //         setShowCreateGroupDialog(false);
  //         setNewGroupName('');
  //     } catch (error) {
  //         console.error('Error creating group:', error);
  //         showAlert('Error creating group. Please try again.');
  //     }
  // };
  const handleCreateGroup = async () => {
    if (!newGroupName.trim()) {
      showAlert("Group name cannot be empty.", "error");
      return;
    }

    try {
      // Replace axiosInstance.post with FetchData
      setIsLoading(true);
      setShowCreateGroupDialog(false);
      await FetchData({
        requestType: "noData", // No data is returned in the response
        method: "POST", // HTTP method
        url: `groups/${newGroupName}`, // API endpoint to create group
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "Content-Type": "application/json",
        },
      });

      // mutate(); // Refetch the groups to update the list
      fetchGroups();
      setShowCreateGroupDialog(false);
      showAlert("Group added successfully", "success");
      setNewGroupName("");
    } catch (error: any) {
      console.error("Error creating group:", error);
      showAlert("Error creating group. Please try again.", "error");
    } finally {
      setIsLoading(false);
    }
  };

  const handleGroupNameChange = (e: any) => {
    setNewGroupName(e.target.value);
  };

  const handleCloseDialog = () => {
    setShowCreateGroupDialog(false);
    setNewGroupName("");
  };

  const handleRenameClick = (group: any) => {
    setGroupToRename(group);
    setNewGroupName(group.groupName);
  };

  const handleRenameCancel = () => {
    setGroupToRename(null);
    setNewGroupName("");
    setRenameError("");
  };

  // const handleRenameConfirm = async () => {
  //     if (!newGroupName.trim()) {
  //         setRenameError('Group name cannot be empty');
  //         return;
  //     }

  //     try {
  //         await axiosInstance.put('https://dev.silzila.com/api/groups/update', {
  //             id: groupToRename.id,
  //             name: newGroupName
  //         });

  //         mutate(); // Refetch the groups to update the list
  //         setGroupToRename(null);
  //         setNewGroupName('');
  //         setRenameError('');
  //     } catch (error) {
  //         console.error('Error renaming group:', error);
  //         showAlert('Error renaming group. Please try again.');
  //     }
  // };
  const handleRenameConfirm = async () => {
    if (!newGroupName.trim()) {
      setRenameError("Group name cannot be empty");
      return;
    }

    try {
      setIsLoading(true);
      await FetchData({
        requestType: "withData",
        method: "PUT",
        url: "groups/update",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "Content-Type": "application/json",
        },
        data: {
          id: groupToRename.id,
          name: newGroupName,
        },
      });

      // mutate(); // Refetch the groups to update the list
      fetchGroups();
      setGroupToRename(null);
      setNewGroupName("");
      setRenameError("");
    } catch (error: any) {
      console.error("Error renaming group:", error);
      showAlert("Error renaming group. Please try again.", "error");
    } finally {
      setIsLoading(false);
    }
  };

  if (error)
    return (
      <div className="loading-error-info">
        <p
          className="loading-default-text"
          style={{
            fontSize: fontSize.medium,
            color: palette.primary.contrastText,
          }}
        >
          Error loading Geoups.
        </p>
      </div>
    );

  return (
    <div className="groupName-container">
      <div
        className="heading"
        style={{
          height: "50px",
          border: "1px solid transparent",
        }}
      >
        <img
          src="/groups.png"
          alt="Groups"
          style={{ width: "24px", height: "24px" }}
        />
        {/* <div> */}
        <h3
          style={{
            // marginTop: "-4px",
            // marginBottom: "5px",
            padding: "0px",
            margin: "0px",
            marginLeft: "6px",
            fontSize: fontSize.large,
            color: palette.primary.contrastText,
          }}
        >
          Groups
        </h3>
        {/* </div> */}
      </div>

      {/*  <div className='groupName-back-link-container'>
                <Link to='/'><button className='back-link-groupName'>Back</button></Link>
            </div> */}

      <div className="groupName-button-add-search-container">
        <div className="groupName-actionButtons">
          <button
            className="groupName-button-group"
            // onClick={() => setShowCreateGroupDialog(true)}
            onClick={() => setShowCreateGroupDialog(true)}
            style={{ fontSize: fontSize.medium }}
          >
            <img
              className="groupName-addIcon groupName-addIconDefault"
              src="/groups_green.png"
              alt="Create Group"
              style={{ width: "15px", height: "15px" }}
            />
            <img
              className="groupName-addIcon groupName-addIconHover"
              src="/groups_white.png"
              alt="Create Group"
              style={{ width: "15px", height: "15px" }}
            />
            Create Group
          </button>
        </div>

        <div className="user-select-action-container">
          <div className="user-actions-container"></div>

          <div
            className="groupName-searchContainer"
            style={{ paddingTop: "1px" }}
          >
            <input
              type="text"
              placeholder="Search Groups"
              value={searchQuery}
              style={{
                fontSize: fontSize.medium,
                color: palette.primary.contrastText,
              }}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="groupName-searchInput"
            />
            <img
              src="/glass.png"
              alt="Search Icon"
              className="groupName-searchIcon"
              style={{ width: "19px", height: "19px" }}
            />
          </div>
        </div>
      </div>

      {isLoading ? (
        <div className="groupName-loading-container">
          <div className="groupName-spinner"></div>
        </div>
      ) : filteredGroups.length === 0 && searchQuery.length === 0 ? (
        <div className="no-user-info">
          <p
            className="loading-default-text"
            style={{
              fontSize: fontSize.medium,
              color: palette.primary.contrastText,
            }}
          >
            No groups have been created yet.
          </p>
        </div>
      ) :  
        // {filteredGroups.length === 0 ? (
        //     <p>No groups have been created yet.</p>
        // ) : (
         (
        <div className="groupName-tableContainer">
          <table className="groupName-groupsTable">
            <thead>
              <tr>
                <th onClick={() => handleSort("groupName")}>
                  Group Name{" "}
                  <span className="icon-wrapper">
                    {getSortIcon("groupName")}
                  </span>
                </th>
                <th onClick={() => handleSort("userCount")}>
                  Total Users{" "}
                  <span className="icon-wrapper">
                    {getSortIcon("userCount")}
                  </span>
                </th>
                <th>Actions</th>
              </tr>
            </thead>

          {filteredGroups.length === 0 && searchQuery.length !== 0 ?  (
            <div className="no-user-info-insideTable">
                <p className="loading-default-text"  
                style={{
                      fontSize: fontSize.medium,
                      color: palette.primary.contrastText,
                    }}>No group found.</p>
              </div>
              ) : (
            <tbody>
                {filteredGroups.map((group: any, index: any) => (
                  <tr
                    key={group.id}
                    onClick={() => handleGroupClick(group.id, group.groupName)}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                    className={groups.includes(index) ? "selectedRow" : ""}
                    style={{ cursor: "pointer" }}
                  >
                    <td className="nameHyperlink">
                      {group.isAdmin ? (
                        <Tooltip title="Admin">
                          <img
                            src="/crown.png"
                            alt="Admin Icon"
                            style={{
                              transform: "translateY(3px)",
                              width: "15px",
                              height: "15px",
                              marginRight: "15px",
                            }}
                          />
                        </Tooltip>
                      ) : (
                        <span
                          style={{
                            display: "inline-block",
                            width: "15px",
                            height: "15px",
                            marginRight: "15px",
                          }}
                        ></span>
                      )}
                      {group.id === 1
                        ? `(${group.groupName})`
                        : group?.groupName}
                    </td>
                    <td>{group.userCount}</td>
                    <td
                      className={`groupName-action-container ${
                        index === 2 ? "first-row-actions" : ""
                      }`}
                      style={{ verticalAlign: "center", textAlign: "center" }}
                    >
                      {group.id !== 1 && (
                        <div className="group-img-icon">
                          <button
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent triggering the row click
                              deleteGroup(index);
                            }}
                            style={{
                              background: "none",
                              border: "none",
                            }}
                          >
                            <Tooltip title="Delete Group">
                              <img
                                src={
                                  hoveredIndex === index
                                    ? "/delete_red.png"
                                    : "/delete_white.png"
                                }
                                alt="Delete Group"
                                style={{
                                  marginBottom: "-2px",
                                  width: "17px",
                                  height: "17px",
                                }}
                              />
                            </Tooltip>
                          </button>

                          <button
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent triggering the row click
                              handleRenameClick(group);
                            }}
                            style={{
                              background: "none",
                              border: "none",
                              padding: 0,
                              cursor: "pointer",
                              marginLeft: "5px",
                              marginRight: "5px",
                            }}
                          >
                            <Tooltip title="Rename Group Name">
                              <img
                                src={
                                  hoveredIndex === index
                                    ? "/edit.png"
                                    : "/edit_white.png"
                                }
                                alt="Rename Group Name"
                                style={{
                                  marginTop: "1px",
                                  width: "14px",
                                  height: "15px",
                                }}
                              />
                            </Tooltip>
                          </button>
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
            )}
          </table>
        </div>
      )}

      <Dialog open={showDeleteConfirmation} onClose={cancelDelete}>
        <div className="groupName-remove-modal">
          <div className="groupName-remove-modalContent-close" style={{visibility: "hidden"}}>
            <Tooltip title="Close">
              <CloseIcon
                onClick={() => setShowDeleteConfirmation(false)}
                sx={{
                  fontSize: "large",
                  color: "#545454",
                  cursor: "pointer",
                  "&:hover": {
                    color: "red",
                  },
                }}
              />
            </Tooltip>
          </div>
          <div className="groupName-remove-modalContent-p">
            <p
              style={{
                fontSize: fontSize.medium,
                color: palette.primary.contrastText,
              }}
            >
              {" "}
              Are you sure you want to delete {groupToDelete?.groupName} Group?
            </p>
          </div>

          <div className="groupName-modal-buttons">
            <button
              onClick={cancelDelete}
              className="groupName-modal-cancel"
              style={{ fontSize: fontSize.medium, }}
            >
              Cancel
            </button>
            <button
              onClick={confirmDeleteGroup}
              className="groupName-modal-confirm"
              style={{ fontSize: fontSize.medium, }}
            >
              Confirm
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog open={showCreateGroupDialog} onClose={handleCloseDialog}>
        <div className="workspaceModal">
          <div className="workspaceModalContent">
            <h3 style={{fontSize:fontSize.large ,color:palette.primary.contrastText}}>Create Group</h3>


          <div className="workspaceModalContentInput">
            <input
              type="text"
              style={{
                fontSize: fontSize.medium,
                color: palette.primary.contrastText,
              }}
              placeholder="Enter group name"
              value={newGroupName}
              onChange={handleGroupNameChange}
            />
          </div>
          {/* Error message section */}

          <div className="workspaceModalButtons">
            <button
              className="workspaceModalCancel"
              onClick={handleCloseDialog}
              style={{ fontSize: fontSize.medium }}
            >
              Cancel
            </button>
            <button
              className="workspaceSaveButton"
              onClick={handleCreateGroup}
              style={{ fontSize: fontSize.medium }}
            >
              Confirm
            </button>
          </div>
        </div>
        </div>
      </Dialog>

      <Dialog open={!!groupToRename} onClose={handleRenameCancel}>
        {groupToRename && (
      <div className="workspaceModal">
        <div className="workspaceModalContent">
        <h3 style={{fontSize:fontSize.large,color:palette.primary.contrastText}}>Rename Group: {groupToRename.groupName}</h3>
        <div className="workspaceModalContentInput">

              <input
                type="text"
                value={newGroupName}
                style={{
                  fontSize: fontSize.medium,
                  color: palette.primary.contrastText,
                }}
                onChange={handleGroupNameChange}
              />
            </div>

            {renameError && (
              <p
                className="errorMessage"
                style={{
                  fontSize: fontSize.medium,
                  color: palette.primary.contrastText,
                }}
              >
                {renameError}
              </p>
            )}
          <div className="workspaceModalButtons">
              <button
                onClick={handleRenameCancel}
                className="workspaceModalCancel"
                style={{ fontSize: fontSize.medium }}
              >
                Cancel
              </button>
              <button
                onClick={handleRenameConfirm}
                className="workspaceSaveButton"
                style={{ fontSize: fontSize.medium }}
              >
                Rename
              </button>
            </div>
          </div>
          </div>
        )}
      </Dialog>

      <NotificationDialog
        openAlert={openAlert}
        severity={severity}
        testMessage={testMessage}
        onCloseAlert={() => {
          setOpenAlert(false);
          setTestMessage("");
        }}
      />
    </div>
  );
};

export default GroupName;
