import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import User from '../Components/User';
import Navbar from '../Components/Navbar';
import Header from '../Components/Header';
import "./pages.css";
import { fontSize, palette } from '..';

const AdminUser = () => {
  

  const Breadcrumb = () => {
    return (
        <div className="breadcrumb">
            <div className="breadcrumb-header">

                <Link to="/" className="breadcrumb-link">
                  <a style={{fontSize:fontSize.medium,color:palette.primary.contrastText}}>Home</a>
                </Link>
               
                <span className="breadcrumb-current" style={{fontSize:fontSize.medium,color:palette.primary.contrastText}}>Users</span>
            </div>
        </div>
    );
};
  const [isCollapsed, setIsCollapsed] = useState(() => {
    const storedState = typeof window !== 'undefined' ? localStorage.getItem('navbarCollapsed') : null;
    return storedState ? JSON.parse(storedState) : false;
});

const toggleNavbar = () => {
    const newState = !isCollapsed;
    setIsCollapsed(newState);
    if (typeof window !== 'undefined') {
        localStorage.setItem('navbarCollapsed', JSON.stringify(newState));
    }
};

useEffect(() => {
    const storedState = localStorage.getItem('navbarCollapsed');
    if (storedState) {
        setIsCollapsed(JSON.parse(storedState));
    }
}, []);

  return (
    <>
      <div className="" style={{padding:'0.5rem'}}><Header /></div>
      <div className="layout-container">
          <div className={`navbar-container ${isCollapsed ? 'collapsed' : ''}`}>
            <Navbar isCollapsed={isCollapsed} toggleNavbar={toggleNavbar} />
          </div>
          <div className="component-container">
            <Breadcrumb />
            <User />
          </div>
      </div>
    </>
  );
}

export default AdminUser;
