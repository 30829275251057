import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { Tooltip } from "@mui/material";
import { palette } from "..";

const StepSlider = ({
  role,
  column,
  initialValue,
  hideCreate,
  onPermissionChange,
}) => {
  const [sliderValue, setSliderValue] = useState(initialValue);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (role === "owner" || role === "moderator") {
      setSliderValue(100); // Full privileges (Create)
      setDisabled(true); // Disable slider for owner and moderator
    } else if (role === "creator") {
      if (column === 1) {
        setSliderValue(25); // None for first column (dbconnection)
      } else {
        setSliderValue(100); // Create for other columns
      }
      setDisabled(true); // Disable slider for standard creator role
    } else if (role === "player") {
      if (column === 5) {
        setSliderValue(50); // View only
      } else {
        setSliderValue(25);
      }
      setDisabled(true); // Disable slider for player
    } else if (role === "custom_creator") {
      if (column === 1) {
        setSliderValue(25);
        setDisabled(true);
      } else {
        setDisabled(false);
        if (column === 2) {
          // console.log(column, sliderValue);
          setSliderValue(mapPrivilegeIdToSliderValue(initialValue)); // Set value dynamically from slider
        } else if (column === 3) {
          // console.log(column, sliderValue);
          setSliderValue(mapPrivilegeIdToSliderValue(initialValue)); // Set value dynamically from slider for other columns
        } else if (column === 4) {
          // console.log(column, sliderValue);
          setSliderValue(mapPrivilegeIdToSliderValue(initialValue)); // Set value dynamically from slider
        } else if (column === 5) {
          // console.log(column, sliderValue);
          if (!initialValue) {
            setSliderValue(50); // Set value dynamically from slider for other columns
          } else {
            setSliderValue(mapPrivilegeIdToSliderValue(initialValue)); // Set value dynamically from slider for other columns
          }
        }
      }
    } else {
      setSliderValue(25); // None (for any unhandled role)
      setDisabled(false); // Default to disabled slider
    }
  }, [
    role,
    column, // sliderValue,
    initialValue,
  ]);

  const handleChange = (e, newValue) => {
    if (column === 5 && newValue < 50) {
      setSliderValue(50);
    } else {
      setSliderValue(newValue);
    }
    console.log("value changed to:", newValue);
    if (newValue > 25)
      onPermissionChange(column, mapSliderValueToPrivilegeId(newValue));
    if (newValue === 25) {
      onPermissionChange(column, 0);
    }
  };

  const mapPrivilegeIdToSliderValue = (privilegeId) => {
    switch (privilegeId) {
      case 1:
        return 100; // Create
      case 2:
        return 75; // Edit
      case 3:
        return 50; // View
      case 0:
        return 25; // None
      default:
        return 25; // None
    }
  };

  const mapSliderValueToPrivilegeId = (value) => {
    switch (value) {
      case 25:
        return null; // None
      case 50:
        return 3; // View
      case 75:
        return 2; // Edit
      case 100:
        return 1; // Create
      default:
        return null;
    }
  };

  return (
    <Tooltip title={disabled ? "Disabled" : null} arrow>
      <Box sx={{ width: "100%", color: "black" }}>
        <Slider
          value={sliderValue}
          step={25}
          min={hideCreate ? 50 : 25}
          max={hideCreate ? 75 : 100}
          // valueLabelDisplay="auto"
          valueLabelFormat={(value) => {
            switch (value) {
              case 25:
                if (column === 5) {
                  return "None is not allowed in Playbook";
                } else {
                  return "None";
                }
              case 50:
                return "View";
              case 75:
                return "Edit";
              case 100:
                return "Create";
              default:
                return "";
            }
          }}
          aria-label="Slider"
          disabled={disabled}
          onChange={handleChange}
          sx={
            !disabled
              ? {
                  width: hideCreate ? "54%" : "79%",
                  color: palette.secondary.light,
                  "& .MuiSlider-thumb": {
                    backgroundColor: palette.secondary.light, // Thumb color
                    border: `2px solid ${palette.secondary.light}`,
                    // border: "none",
                    width: "17px",
                    height: "17px",
                    "&:hover, &.Mui-focusVisible": {
                      boxShadow: "0px 0px 0px 8px rgba(175, 153, 219, 0.16)",
                    },
                    "&.Mui-active": {
                      boxShadow: "0px 0px 0px 14px rgba(175, 153, 219, 0.16)",
                    },
                  },
                  "& .MuiSlider-track": {
                    backgroundColor: palette.secondary.light, // Fill color
                    // Added grey color for none access in case of playbook
                    background:
                      column === 5
                        ? `linear-gradient(to right, ${
                            palette.primary.grey
                          } 0%, ${palette.primary.grey} ${
                            2500 / (sliderValue - 25)
                          }%, ${palette.secondary.light} ${
                            2500 / (sliderValue - 25)
                          }%, ${palette.secondary.light} 100%)`
                        : null,
                    border: "none",
                  },
                  "& .MuiSlider-rail": {
                    backgroundColor: palette.secondary.light, // Unfilled track color
                  },
                  "& .MuiSlider-thumb:hover": {
                    boxShadow:
                      "0px 0px 0px 8px rgba(175, 153, 219, 0.16) !important",
                  },
                  "& .MuiSlider-thumb:active": {
                    boxShadow:
                      "0px 0px 0px 8px rgba(175, 153, 219, 0.16) !important",
                  },
                  "& .MuiSlider-valueLabel": {
                    backgroundColor: palette.secondary.light,
                  },
                }
              : {
                  width: "79%",
                  "& .MuiSlider-thumb": {
                    backgroundColor: palette.primary.grey, // Thumb color
                    border: `2px solid ${palette.primary.grey}`,
                    width: "17px",
                    height: "17px",
                    "&:hover, &.Mui-focusVisible": {
                      boxShadow: "0px 0px 0px 8px rgba(175, 153, 219, 0.16)",
                    },
                    "&.Mui-active": {
                      boxShadow: "0px 0px 0px 14px rgba(175, 153, 219, 0.16)",
                    },
                  },
                  "& .MuiSlider-track": {
                    backgroundColor: palette.primary.grey, // Fill color
                    border: "none",
                  },
                  "& .MuiSlider-rail": {
                    backgroundColor: palette.primary.grey, // Unfilled track color
                  },
                }
          }
        />
      </Box>
    </Tooltip>
  );
};

export default StepSlider;
